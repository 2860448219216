import BaseService from './BaseService';

export default class MonthlyFaxServices extends BaseService {
    async getMonthlyFaxReview(filters) {
        try {
            const url = `/api/monthlyFaxReviewReport`;
            const config = {
                params: filters
            };
            
            return await this.get(url, config);
        } catch (error) {
            console.error("Error in getMonthlyFaxReview:", error);
            throw error;
        }
    }

    async getDocument(record) {
        try {
            const url = `/api/monthlyFaxReviewReport/${record.id}/document`;
            const config = {
                responseType: 'blob'
            };
            
            const response = await this.get(url, config);
            const blob = new Blob([response], { type: 'application/pdf' });
            return blob;
        } catch (error) {
            console.error('Error while downloading document: ', error);
            throw error;
        }
    }
    
    async handelIsReview(id) {
        try {
            const url = `/api/monthlyFaxReviewReport/${id}/Review`;
            return await this.post(url);
        } catch (error) {
            console.error(`Review failed for ID ${id}:`, error);
            throw error;
        }
    }
    
    async handleFaxGeneratedRetry(id) {
        try {
            const url = `/api/monthlyFaxReviewReport/${id}/handleFaxGeneratedRetry`;
            return await this.post(url);
        } catch (error) {
            console.error(`Retry failed for ID ${id}:`, error);
            throw error;
        }
    }
    
    async regenrateMonthlyFaxReport(clinicName, schedule) {
        try {
            const url = `/api/monthlyFaxReviewReport/regenerate-clinic-monthly-fax`;
            const data = { clinicName, schedule };
            
            return await this.post(url, data);
        } catch (error) {
            console.error('Error while regenerating monthly fax report: ', error);
            throw error;
        }
    }
}

// Create a singleton instance to export
const monthlyFaxServices = new MonthlyFaxServices();

// Export the methods from the instance
export const getMonthlyFaxReview = monthlyFaxServices.getMonthlyFaxReview.bind(monthlyFaxServices);
export const getDocument = monthlyFaxServices.getDocument.bind(monthlyFaxServices);
export const handelIsReview = monthlyFaxServices.handelIsReview.bind(monthlyFaxServices);
export const handleFaxGeneratedRetry = monthlyFaxServices.handleFaxGeneratedRetry.bind(monthlyFaxServices);
export const regenrateMonthlyFaxReport = monthlyFaxServices.regenrateMonthlyFaxReport.bind(monthlyFaxServices);

