import BaseService from './BaseService';
import { formatDateForApi } from '../utils/dateFormat';

export default class DashboardService extends BaseService {
    async handleDashboardData(intent, startDate, endDate, targetValue, kpiValue, selectedEntityOption, 
        selectedAppointmentTypeOption, selectedAppointmentStatusOption, pageNo, limit, searchField) {
        
        const endpoint = '/kpi/appointments/targets';
        
        // Configure parameters for pagination
        const config = {
            params: {
                pageNo: pageNo,
                limit: limit
            }
        };
        
        const payload = {
            intent: intent,
            payload: {
                startDate: formatDateForApi(startDate),
                endDate: formatDateForApi(endDate),
                apptType: selectedAppointmentTypeOption,
                isFollowUp: selectedEntityOption,
                apptStatus: selectedAppointmentStatusOption,
                setTargetValue: targetValue,
                setKpiValue: kpiValue,
                search: searchField
            },
            context: null
        };

        try {
            return await this.post(endpoint, payload, config);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            throw new Error('Oops! An error occurred while fetching dashboard data.');
        }
    }
}

// Create a singleton instance
const dashboardService = new DashboardService();

// Export the methods from the instance
export const handleDashboardData = dashboardService.handleDashboardData.bind(dashboardService);

