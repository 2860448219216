import React, { useState, useEffect, useRef } from 'react'
import '../styles/Details.css'
import { DateRangePicker } from 'react-date-range'
import CalendarGrey from '../assets/Calendar Dark.svg'
import CalendarBlue from '../assets/Calendar Blue.svg'
import { FilterOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Tooltip, Tag, Modal, Card, Form, Spin } from 'antd'
import moment from 'moment'
import { formateDateForAuditlog, formatDateForApi, formatDateForIntake } from '../utils/dateFormat'
import FilterComponent from '../components/FilterComponent'
import IntakeService, { getAllChannels, getAllClinics, getAllGHLUsers, getGHLUserById } from '../services/IntakeService'
import { convertToTitleCase } from '../utils/stringUtils'
import { insertOrupdateGhlContact } from '../services/GHLService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import generate from '../utils/generatePatientMatchingErrorModalContent'
import CreateNewPatientForm from '../components/IntakeProcess/CreateNewPatientForm'
import UpdateGeneralInfoForm from '../components/IntakeProcess/UpdateGeneralInfoForm'
import IntakeTable from '../components/IntakeProcess/IntakeTable'
import ViewModal from '../components/IntakeProcess/Modals/ViewModal'
import CreateModal from '../components/IntakeProcess/Modals/CreateModal'
import EditModal from '../components/IntakeProcess/Modals/EditModal'
import useDebounce from '../hooks/useDebounce'
import { notify } from '../utils/notify'
import ComingSoonModal from '../components/IntakeProcess/Modals/CommingSoonModal'
import BookFollowupModal from '../components/IntakeProcess/Modals/BookFollowupModal'
import BookFollowup from '../components/IntakeProcess/BookFollowUp/BookFollowup'
dayjs.extend(customParseFormat)
const { Search } = Input

function Intake() {
  document.title = 'LunaJoy Dashboard'
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalComponent, setModalComponent] = useState()
  const [data, setData] = useState([])
  const [clinicNames, setClinicNames] = useState([])
  const [channelNames, setChannelNames] = useState([])
  const [loading, setLoading] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const datePickerRef = useRef(null)
  const buttonRef = useRef(null)
  const [filterClinic, setFilterClinic] = useState(null)
  const [filterChannel, setFilterChannel] = useState(null)
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [totalItems, setTotalItems] = useState(1)
  const [searchField, setSearchField] = useState('')
  const filterCardRef = useRef(null)
  const filterButtonRef = useRef(null)
  const [hasGeneralInfo, setHasGeneralInfo] = useState(false)
  const [hasInsurance, setHasInsurance] = useState(false)
  const [hasCreditCard, setHasCreditCard] = useState(false)
  const [hasConsent, setHasConsent] = useState(false)
  const [hasBooking, setHasBooking] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [editModalContent, setEditModalContent] = useState('')
  const [createModalContent, setCreateModalContent] = useState('')
  const [isPdfModal, setIsPdfModal] = useState(false)
  const [isAppointmentModal, setIsAppointmentModal] = useState(false)
  const [createModalTitle, setCreateModalTitle] = useState('')
  const [editModalTitle, setEditModalTitle] = useState('')
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false)
  const [isBookFollowupModalOpen, setIsBookFollowupModalOpen] = useState(false)
  const [bookFollowupModalTitle, setBookFollowupModalTitle] = useState('')
  const [bookFollowupModalContent, setBookFollowupModalContent] = useState('')
  const [isInsuranceModal, setIsInsuranceModal] = useState(false)

  const [form] = Form.useForm()
  const dateFormat = 'MM-DD-YYYY'

  const getSixDaysAgoFromCurrentDate = () => {
    const today = new Date()
    const sixDaysAgo = new Date(today)
    sixDaysAgo.setDate(today.getDate() - 6)
    return sixDaysAgo
  }

  const [dateRange, setDateRange] = useState([
    {
      // startDate: getSixDaysAgoFromCurrentDate(),
      startDate: new Date(2020, 0, 1),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  // dateFormatGeneric
  const startDate = formateDateForAuditlog(dateRange[0].startDate)
  const endDate = formateDateForAuditlog(dateRange[0].endDate)
  const momentFormatStartDate = moment(startDate).format('D/M/YYYY')
  const momentFormatEndDate = moment(endDate).format('D/M/YYYY')

  const getClinicsAndChannels = async () => {
    const clinics = await getAllClinics()
    if (clinics) {
      setClinicNames(clinics?.sort())
    }

    const channels = await getAllChannels()
    if (channels) {
      const convertedChannels = channels?.sort()
      setChannelNames(convertedChannels)
    }
  }

  useEffect(() => {
    getClinicsAndChannels()
  }, [])

  const mapContactData = (record) => {
    return {
      id: record?.id,
      amdPatientID: record?.amd_patient_id,
      name: record?.name,
      firstname: record?.firstname,
      lastname: record?.lastname,
      patientName: convertToTitleCase(record?.firstname) + ' ' + convertToTitleCase(record?.lastname),
      email: record?.email,
      phone_no: record?.phone_no,
      dob: record?.dob,
      tags: record?.tags,
      ghl_contact_id: record?.ghl_contact_id,
      clinic: record?.clinic_name ? record?.clinic_name : '',
      source: record?.source ? record?.source : '',
      createdAt: record?.ghl_created_at,
      customField: record?.customField,
      updatedAt: record?.updatedAt,
      clinic_name: record?.clinic_name,
      has_general_information: record?.has_general_information,
      has_booking: record?.has_booking,
      has_primary_insurance: record?.has_primary_insurance,
      has_credit_card: record?.has_credit_card,
      has_hipaa_consent_signed: record?.has_hipaa_consent_signed,
      has_email_and_sms_consent_signed: record?.has_email_and_sms_consent_signed,
      state: record?.state
    }
  }

  async function loadData(
    searchField,
    pageNo,
    pageSize,
    startDate,
    endDate,
    filterClinic,
    filterChannel,
    hasBooking,
    hasConsent,
    hasCreditCard,
    hasInsurance,
    hasGeneralInfo
  ) {
    try {
      setLoading(true)
      const details = await getAllGHLUsers(
        searchField,
        pageNo,
        pageSize,
        startDate,
        endDate,
        filterClinic,
        filterChannel,
        hasBooking,
        hasConsent,
        hasCreditCard,
        hasInsurance,
        hasGeneralInfo
      )
      setTotalItems(details?.totalItems === 0 ? 1 : details?.totalItems)
      if (details?.data?.length) {
        setData(details?.data?.map((record) => mapContactData(record)))
      } else {
        setData([])
      }
    } catch (e) {
      console.log(e)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {
          console.log('Error in Fetching data')
        }
      })
    } finally {
      setLoading(false) // Reset loading once data fetching is complete
    }
  }

  const debouncedSearchValue = useDebounce(searchField)

  useEffect(() => {
    setCurrentPage(1)
    setPageLimit(10)
    data.length = 0

    loadData(
      debouncedSearchValue,
      1,
      10,
      startDate,
      endDate,
      filterClinic,
      filterChannel,
      hasBooking,
      hasConsent,
      hasCreditCard,
      hasInsurance,
      hasGeneralInfo
    )
  }, [debouncedSearchValue, startDate, endDate, filterClinic, filterChannel, hasBooking, hasConsent, hasCreditCard, hasInsurance, hasGeneralInfo])

  const handleSearch = (value) => {
    setSearchField(value)
  }

  const handleTableChange = (pagination, filters) => {
    // setFilters(filters);
    setLoading(true)
    setCurrentPage(pagination.current)
    setPageLimit(pagination.pageSize)
    setLoading(false)
    setData([])
    data.length = 0
    loadData(
      searchField,
      pagination.current,
      pagination.pageSize,
      startDate,
      endDate,
      filterClinic,
      filterChannel,
      hasBooking,
      hasConsent,
      hasCreditCard,
      hasInsurance,
      hasGeneralInfo
    )
  }

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowDatePicker(false)
    }

    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false)
    }
  }

  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection])
  }

  const handleToggleDatePicker = () => {
    setShowDatePicker(!showDatePicker)
  }
  const handleClose = () => {
    setIsFilterVisible(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleFilter = () => {
    setIsFilterVisible(true)
  }

  const handleCreate = async (data) => {
    try {
      setCreateModalTitle('')
      setCreateModalContent(
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '1rem'
          }}
        >
          <Spin />
          <p>Creating New Patient...</p>
        </div>
      )
      const formData = {}
      formData.firstname = data.firstname
      formData.lastname = data.lastname
      formData.dob = formatDateForIntake(data.dob.$d)
      formData.phone_no = `+1${data.phone_no}`
      formData.email = data.email
      formData.from = 'intake'
      formData.tags = ['new intake']

      const contact = await insertOrupdateGhlContact('create', formData)

      if (contact?.message) {
        const { title, content } = generate(contact?.message)
        Modal.error({
          title,
          content: <p>{content}</p>,
          okText: 'OK',
          centered: true,
          okButtonProps: { style: { width: 60 } }
        })
      } else if (contact.error) {
        notify('Error', contact.error, 'error')
      } else {
        notify('Patient created successfully.', '')
      }
    } catch (error) {
      console.log(error)
    } finally {
      form.resetFields()
      setIsCreateModalOpen(false)
    }
  }

  const handleCreateCancel = () => {
    setIsCreateModalOpen(false)
    form.resetFields()
  }

  const handleEditCancel = () => {
    setIsEditModalOpen(false)
    form.resetFields()
  }

  const handleAddPatientModal = () => {
    setIsCreateModalOpen(true)
    setCreateModalTitle('Patient Information')
    setCreateModalContent(<CreateNewPatientForm loading={loading} form={form} handleCreate={handleCreate} handleCreateCancel={handleCreateCancel} />)
  }

  const setFormsFieldValues = (data) => {
    form.setFieldValue('firstname', data?.firstname)
    form.setFieldValue('lastname', data?.lastname)
    if (!data.dob) {
      form.setFieldValue('dob', '', dateFormat)
    } else {
      form.setFieldValue('dob', dayjs(formatDateForApi(data?.dob), dateFormat))
    }
    form.setFieldValue('phone_no', data?.phone_no?.slice(2))
    form.setFieldValue('email', data?.email)
    form.setFieldValue('contactid', data?.ghl_contact_id)
  }

  const handleUpdate = async (data) => {
    try {
      setEditModalTitle('')
      setEditModalContent(
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '1rem'
          }}
        >
          <Spin />
          <p>Updating Patient Information...</p>
        </div>
      )

      const formData = {}
      formData.firstname = data.firstname
      formData.lastname = data.lastname
      formData.dob = formatDateForIntake(data.dob.$d)
      formData.phone_no = `+1${data.phone_no}`
      formData.email = data.email

      const contact = await insertOrupdateGhlContact('update', formData, data.contactid)

      if (contact?.message) {
        const { title, content } = generate(contact?.message)
        Modal.error({
          title,
          content: <p>{content}</p>,
          okText: 'OK',
          centered: true,
          okButtonProps: { style: { width: 60 } }
        })
      } else {
        notify('Patient information updated successfully.', '')
      }
    } catch (error) {
      console.log(error)
    } finally {
      form.resetFields()
      setIsEditModalOpen(false)
    }
  }

  const editContent = (record) => {
    setIsEditModalOpen(true)
    setFormsFieldValues(record)
    setEditModalTitle('Patient Information')
    setEditModalContent(<UpdateGeneralInfoForm loading={loading} form={form} handleUpdate={handleUpdate} handleEditCancel={handleEditCancel} />)
  }

  const bookFollowup = (data) => {
    setIsBookFollowupModalOpen(true)
    setBookFollowupModalTitle('Book Follow-up Appointment')

    setBookFollowupModalContent(
      <BookFollowup
        loading={loading}
        rowData={data}
        setIsBookFollowupModalOpen={setIsBookFollowupModalOpen}
        setBookFollowupModalTitle={setBookFollowupModalTitle}
        setBookFollowupModalContent={setBookFollowupModalContent}
        setLoading={setLoading}
      />
    )
  }

  function findAndReplace(array, replacement) {
    return array.map((obj) => {
      // If object id matches the replacement id, replace it
      if (obj.id === replacement.id) {
        return { ...replacement } // Merge properties of obj and replacement
      }
      return obj // Otherwise, return the original object
    })
  }

  const handleRefresh = async (record) => {
    setLoading(true)
    const updatedContact = await getGHLUserById(record.id)
    const mappedContact = mapContactData(updatedContact)
    const updatedData = findAndReplace(data, mappedContact)
    setData(updatedData)
    setLoading(false)
  }

  const handleComingSoonCancel = () => {
    setIsComingSoonModalOpen(false)
  }

  const handleCommingSoon = () => {
    setIsComingSoonModalOpen(true)
  }

  return (
    <div id="dashboard-container" className="responsive-container">
      <div className="row styleFilterRow">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ position: 'relative' }}>
            <span
              style={{
                marginLeft: '18px',
                marginRight: 10,
                fontWeight: 'bold'
              }}
            >
              Total Referral
            </span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : totalItems}</strong>
            </Tag>

            <Button type="link" onClick={handleAddPatientModal} disabled={loading}>
              <span style={{ textDecoration: 'underline' }}>Create New Patient</span>
            </Button>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <label htmlFor="search" className="labelStylingFilter" style={{ fontWeight: 'bold' }}>
              Search Patient:
            </label>
            <Search
              id="search"
              placeholder="Search by First name, Last name, email or phone number"
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              onSearch={handleSearch}
              allowClear
              style={{ width: '25vw' }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span className="dateStyling" style={{ marginRight: '10px' }}>
              Filter
            </span>
            <div style={{ position: 'relative' }}>
              <Tooltip title="Filter">
                <FilterOutlined
                  style={{
                    marginRight: '10px',
                    fontSize: '18px',
                    marginTop: '5px',
                    cursor: 'pointer'
                  }}
                  ref={filterButtonRef}
                  onClick={handleFilter}
                />
              </Tooltip>

              {isFilterVisible && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 100
                  }}
                  ref={filterCardRef}
                >
                  <Card
                    title="Filter Options"
                    bordered={true}
                    extra={
                      <Button type="Close" onClick={handleClose}>
                        <CloseCircleOutlined />
                      </Button>
                    }
                  >
                    <FilterComponent
                      clinicNames={clinicNames}
                      filteredClinic={filterClinic}
                      setFilteredClinic={setFilterClinic}
                      channelNames={channelNames}
                      filteredChannel={filterChannel}
                      setFilteredChannel={setFilterChannel}
                      hasGeneralInfo={hasGeneralInfo}
                      setHasGeneralInfo={setHasGeneralInfo}
                      hasInsurance={hasInsurance}
                      setHasInsurance={setHasInsurance}
                      hasCreditCard={hasCreditCard}
                      setHasCreditCard={setHasCreditCard}
                      hasConsent={hasConsent}
                      setHasConsent={setHasConsent}
                      hasBooking={hasBooking}
                      setHasBooking={setHasBooking}
                      from={'Intake'}
                    />
                  </Card>
                </div>
              )}
            </div>
            <span className="dateStyling">{momentFormatStartDate + ' to ' + momentFormatEndDate}</span>
            <span ref={buttonRef} onClick={handleToggleDatePicker} className="marginAdjustCalendarToggleButton">
              {showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}
            </span>
            {showDatePicker && (
              <div
                className="date-picker-container"
                ref={datePickerRef}
                style={{
                  position: 'absolute',
                  zIndex: '9999',
                  top: '10.5rem',
                  display: 'flex',
                  justifyContent: 'end',
                  right: '30px'
                }}
              >
                <DateRangePicker ranges={dateRange} onChange={handleDateChange} />
              </div>
            )}
          </div>
        </div>
      </div>

      <IntakeTable
        loading={loading}
        data={data}
        currentPage={currentPage}
        pageLimit={pageLimit}
        totalItems={totalItems}
        handleTableChange={handleTableChange}
        setIsModalOpen={setIsModalOpen}
        setModalTitle={setModalTitle}
        setIsPdfModal={setIsPdfModal}
        setIsAppointmentModal={setIsAppointmentModal}
        setModalComponent={setModalComponent}
        editContent={editContent}
        setLoading={setLoading}
        handleRefresh={handleRefresh}
        handleComingSoon={handleCommingSoon}
        bookFollowup={bookFollowup}
        setIsInsuranceModal={setIsInsuranceModal}
      />

      <ViewModal
        modalTitle={modalTitle}
        isModalOpen={isModalOpen}
        isAppointmentModal={isAppointmentModal}
        handleCancel={handleCancel}
        isPdfModal={isPdfModal}
        loading={loading}
        modalComponent={modalComponent}
        isInsuranceModal={isInsuranceModal}
      />
      <CreateModal isCreateModalOpen={isCreateModalOpen} createModalContent={createModalContent} title={createModalTitle} />
      <EditModal isEditModalOpen={isEditModalOpen} editModalContent={editModalContent} title={editModalTitle} />
      <ComingSoonModal isComingSoonModalOpen={isComingSoonModalOpen} handleCancel={handleComingSoonCancel} />
      <BookFollowupModal
        isBookFollowupModalOpen={isBookFollowupModalOpen}
        bookFollowupModalContent={bookFollowupModalContent}
        title={bookFollowupModalTitle}
      />
    </div>
  )
}

export default Intake
