import BaseService from './BaseService';

export default class ParticipationMatrixService extends BaseService {
  async getParticipationMatrixData(params) {
    const endpoint = '/api/getParticipationMatrixData';
    
    const config = {
      params: {
        search: params?.search,
        page: params.page,
        limit: params.limit
      }
    };
    
    try {
      return await this.get(endpoint, config);
    } catch (error) {
      console.error("Error fetching participation matrix data:", error);
      throw new Error('Could not fetch participation matrix data.');
    }
  }

  async uploadCredentialingSummary(file) {
    const endpoint = '/api/uploadParticipationMatrix';
    
    // Configure for file upload
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    
    try {
      return await this.post(endpoint, file, config);
    } catch (error) {
      console.error("Error uploading credentialing summary:", error);
      throw new Error('Could not upload credentialing summary.');
    }
  }

  async getInsuranceMappingList(params) {
    const endpoint = '/api/getInsuranceMappingData';
    
    const config = {
      params: {
        search: params?.search,
        page: params?.page,
        limit: params?.limit
      }
    };
    
    try {
      return await this.get(endpoint, config);
    } catch (error) {
      console.error("Error fetching insurance mapping data:", error);
      throw new Error('Could not fetch insurance mapping data.');
    }
  }
}

// Create a singleton instance
const participationMatrixService = new ParticipationMatrixService();

// Export the methods from the instance
export const getParticipationMatrixData = participationMatrixService.getParticipationMatrixData.bind(participationMatrixService);
export const uploadCredentialingSummary = participationMatrixService.uploadCredentialingSummary.bind(participationMatrixService);
export const getInsuranceMappingList = participationMatrixService.getInsuranceMappingList.bind(participationMatrixService);
