import BaseService from './BaseService';
import { BACKEND_URL } from "../utils/urls";

export default class PowerBIService extends BaseService {
    async getAccessToken() {
        try {
            return await this.get('/api/powerbi/accesstoken');
        } catch (error) {
            console.error("Error getting access token:", error);
            throw error;
        }
    }

    async getEmbeddedToken(accessToken) {
        const data = {
            accessToken: accessToken
        };
        
        try {
            return await this.post('/api/powerbi/embedtoken', data);
        } catch (error) {
            console.error("Error getting embedded token:", error);
            throw error;
        }
    }

    async getConfigInfo() {
        try {
            return await this.get('/api/powerbi/getPowerBIConfigInfo');
        } catch (error) {
            console.error("Error getting config info:", error);
            throw error;
        }
    }
}

// Create a singleton instance
const powerBIService = new PowerBIService();

// Export the methods from the instance
export const getAccessToken = powerBIService.getAccessToken.bind(powerBIService);
export const getEmbeddedToken = powerBIService.getEmbeddedToken.bind(powerBIService);
export const getConfigInfo = powerBIService.getConfigInfo.bind(powerBIService);
