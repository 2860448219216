import BaseService from './BaseService';

export default class InsuranceService extends BaseService {
    async getInsurances() {
        try {
            const response = await this.get('/api/insurances');
            return response;
        } catch (error) {
            console.error('Error fetching insurances:', error);
            throw new Error('Could not fetch insurances.');
        }
    }

    async insertInsurance(data) {
        try {
            const response = await this.post('/api/insurances', data);
            return response;
        } catch (error) {
            console.error('Error inserting insurance:', error);
            throw new Error('Could not insert insurance.');
        }
    }

    async getInsuranceVerifications(parameters) {
        try {
            const params = new URLSearchParams(parameters).toString();
            const response = await this.get(`/api/insurance-verifications?${params}`);
            return response;
        } catch (error) {
            console.error('Error fetching insurance verifications:', error);
            throw new Error('Could not fetch insurance verifications.');
        }
    }
}

// Create a singleton instance to export
const insuranceService = new InsuranceService();

// Export the methods from the instance
export const getInsurances = insuranceService.getInsurances.bind(insuranceService);
export const insertInsurance = insuranceService.insertInsurance.bind(insuranceService);
export const getInsuranceVerifications = insuranceService.getInsuranceVerifications.bind(insuranceService);