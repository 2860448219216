import BaseService from './BaseService';
import axios from 'axios';
// import config from 'config';

export default class AuditLogsServices extends BaseService {
  async getAuditLogs() {
    try {
      return await this.get('/api/auditLogs');
    } catch (error) {
      console.error("Error fetching audit logs:", error);
      throw new Error('Could not fetch audit logs.');
    }
  }

  async getAuditLogsClinics(clinic, type, status, startDate, endDate, patientName = null, patientPhone = null, frequency = null) {
    const endpoint = '/api/auditLogs';
    
    const config = {
      params: {
        type: type ? type : null,
        status: status ? status : null,
        clinic: clinic ? clinic : null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        patientName: patientName,
        patientPhone: patientPhone,
        frequency: frequency ? frequency : null
      }
    };

    try {
      return await this.get(endpoint, config);
    } catch (error) {
      console.error("Error fetching audit logs by criteria:", error);
      throw new Error('Could not fetch audit logs.');
    }
  }

  async getDocument(record) {
    const endpoint = `/api/auditLogs/${record.id}/document`;
    
    try {
      // We need direct axios here because we need blob response type
      const config = this.getAxiosConfig({ responseType: 'blob' });
      const url = `${this.getBaseUrl()}${endpoint}`;
      const response = await axios.get(url, config);
      
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const windowUrl = window.URL.createObjectURL(blob);

      return windowUrl;
    } catch (error) {
      console.error('Error while downloading document: ', error);
      throw new Error('Could not download document.');
    }
  }

  async downloadDocument(record) {
    try {
      const windowUrl = await this.getDocument(record);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = windowUrl;
      a.download = `${record.clinic}-${record.channel}-document.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(windowUrl);

      return true;
    } catch (error) {
      console.error('Error while downloading document: ', error);
      throw new Error('Could not download document.');
    }
  }

  async handleRetry(id) {
    try {
      await this.post(`/api/auditLogs/${id}/retry`);
      console.log(`Retry successful for ID ${id}`);
      return true;
    } catch (error) {
      console.error(`Retry failed for ID ${id}:`, error.message);
      throw new Error(`Retry failed for ID ${id}.`);
    }
  }

  async canDownloadReport() {
    let auditDownloadAccessUsers = ['guillermo.solorzano@hellolunajoy.com', 'juan.lora@hellolunajoy.com'];
    if(window.location.hostname == 'portal-dev.hellolunajoy.com' || window.location.hostname == 'localhost'){
      auditDownloadAccessUsers = [...auditDownloadAccessUsers, 'ravalii.chinnam@hellolunajoy.com', 'bhavneesh.goyal@hellolunajoy.com', 'gajanan.mahalkar@hellolunajoy.com'];
    }
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    return auditDownloadAccessUsers.includes(user.email);
  }

  // Helper method to get base URL for direct axios calls
  getBaseUrl() {
    const { BACKEND_URL } = require('../utils/urls');
    return BACKEND_URL;
  }
}

// Create a singleton instance
const auditLogsServices = new AuditLogsServices();

// Export the methods from the instance
export const getAuditLogs = auditLogsServices.getAuditLogs.bind(auditLogsServices);
export const getAuditLogsClinics = auditLogsServices.getAuditLogsClinics.bind(auditLogsServices);
export const getDocument = auditLogsServices.getDocument.bind(auditLogsServices);
export const downloadDocument = auditLogsServices.downloadDocument.bind(auditLogsServices);
export const handleRetry = auditLogsServices.handleRetry.bind(auditLogsServices);
export const canDownloadReport = auditLogsServices.canDownloadReport.bind(auditLogsServices);