import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import BasicLayout from './components/BasicLayout';
import LoginPage from './pages/LoginPage';
import LoginFailure from './pages/GoogleLoginFailure';
import LoginSuccess from './pages/GoogleLoginSuccess';
import Cookies from 'js-cookie';
import IsIslandBrowser from './utils/browserDetection';
import { Result, Button, Typography } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { getUserData, isTokenExpired } from './utils/auth';

function App() {
  // Set the initial authentication state based on the presence of the token in localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [isIsland, setIsIsland] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check browser on component mount
    setIsIsland(IsIslandBrowser());
    
    // Check if token exists and is not expired
    const user = getUserData();
    if (user && user.jwtToken && !isTokenExpired(user.jwtToken)) {
      setIsAuthenticated(true);
    } else if (user) {
      // If user exists but token is expired or invalid, remove it
      localStorage.removeItem('user');
      setIsAuthenticated(false);
    }
    
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (!isIsland) {
    return (
      <div style={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        background: '#f0f2f5'
      }}>
        <Result
          status="error"
          icon={<StopOutlined style={{ fontSize: '60px' }} />}
          title={<Typography.Title level={2} style={{ fontSize: '32px', color: '#ff4d4f' }}>Access Restricted</Typography.Title>}
          subTitle={<Typography.Text style={{ fontSize: '20px', display: 'block', margin: '16px 0' }}>This application can only be accessed through the Island web browser.</Typography.Text>}
          extra={
            <Typography.Paragraph style={{ fontSize: '18px', textAlign: 'center' }}>
              Please open this website using the Island browser to continue.
            </Typography.Paragraph>
          }
        />
      </div>
    );
  }

  const handleLogin = () => {
    // Implement your login logic here, and set isAuthenticated to true upon successful login
    navigate("/")
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    // Implement logout logic here, and set isAuthenticated to false upon logout
    setIsAuthenticated(false);
    localStorage.removeItem('user');
    Cookies.remove('session');
    Cookies.remove('session.sig');
    navigate("/login"); // Redirect to the login page after logout
  };

  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route exact path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route exact path="/login/success" element={<LoginSuccess />} />
        <Route exact path="/login/failure" element={<LoginFailure />} />

        {/* Private Routes */}
        {isAuthenticated ? (
          <Route path="/*" element={<BasicLayout onLogout={handleLogout} />} />
        ) : (
          <Route path="/*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </div>
  );
}

export default App;
