import React from 'react';
import CarePartnerForm from '../components/CarePartnerForm';

function AddClinic() {
    return (
        <>
            <h1 style={{ marginBlock: "20px", textAlign: "center" }}>Clinic Onboarding</h1>
            <CarePartnerForm />
        </>
    );
}
export default AddClinic;