import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import CalendarGrey from "../assets/Calendar Dark.svg";
import CalendarBlue from "../assets/Calendar Blue.svg";
import "react-date-range/dist/styles.css"; // Import the styles
import "react-date-range/dist/theme/default.css";
import "react-date-range/dist/styles.css"; // Import the styles for the DateRange component
import "react-date-range/dist/theme/default.css";
import "../styles/Dashboard.css";
import {
  entityOptionState,
  appointmentStatusOptionState,
  appointmentTypeOptionState,
  dateRangeState,
} from "../state/bookingGlobalState";
import { useRecoilState } from "recoil";
import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { handleDashboardData } from "../services/DashboardService";
import { Card } from "antd";
import FilterComponent from "../components/FilterComponent";
import moment from "moment";

import { FilterFilled } from "@ant-design/icons";
import { Button, Modal, Table, Space, Input, Spin, Tag } from "antd";

import { formatDateAndTimeInlocal } from "../utils/dateFormat";
import { convertToTitleCase, capitalizeFirstLetter } from "../utils/stringUtils";
import useDebounce from "../hooks/useDebounce";

const { Column } = Table;

function BookingDetails() {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState();
  const [loading, setLoading] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedEntityOption, setSelectedEntityOption] = useRecoilState(entityOptionState);
  const [selectedAppointmentTypeOption, setSelectedAppointmentTypeOption] = useRecoilState(appointmentTypeOptionState);
  const [selectedAppointmentStatusOption, setSelectedAppointmentStatusOption] =
    useRecoilState(appointmentStatusOptionState);
  const [searchField, setSearchField] = useState("");
  const [targetValue, setTargetValue] = useState(null);
  const [data, setData] = useState([]);
  const [kpiValue, setKpiValue] = useState([]);

  const datePickerRef = useRef(null);
  const buttonRef = useRef(null);

  const filterCardRef = useRef(null);
  const filterButtonRef = useRef(null);

  const onSearch = (value, _e, info) => {
    setSearchField(value);
  };

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination.current);
    setPageLimit(pagination.pageSize);
    setData([]);
    data.length = 0;
    fetchDashboardData(
      "get-appointments",
      startDate,
      endDate,
      targetValue,
      kpiValue,
      pagination.current,
      pagination.pageSize
    );
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }

    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false);
    }
  };

  const [dateRange, setDateRange] = useRecoilState(dateRangeState);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 138, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90, border: 0 }}
          >
            Reset
          </Button>
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 40 }}>
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const startDate = dateRange[0].startDate;
  const endDate = dateRange[0].endDate;

  const momentFormatStartDate = moment(startDate).format("D/M/YYYY");
  const momentFormatEndDate = moment(endDate).format("D/M/YYYY");

  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleToggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const clearFilter = () => {
    setSearchField(""); // Clear the search input
    // You can also add logic to clear any filtered data or reset other filter parameters here
  };

  const debouncedSearchValue = useDebounce(searchField);

  useEffect(() => {
    setCurrentPage(1);
    setPageLimit(10);
    setData([]);
    data.length = 0;
    fetchDashboardData("get-appointments", startDate, endDate, targetValue, kpiValue, 1, 10);
  }, [
    selectedEntityOption,
    selectedAppointmentTypeOption,
    selectedAppointmentStatusOption,
    debouncedSearchValue,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fetchDashboardData = async (intent, startDate, endDate, targetValue, kpiValue, pageNo, pageSize) => {
    try {
      setLoading(true);
      const response = await handleDashboardData(
        intent,
        startDate,
        endDate,
        targetValue,
        kpiValue,
        selectedEntityOption,
        selectedAppointmentTypeOption,
        selectedAppointmentStatusOption,
        pageNo,
        pageSize,
        searchField
      );
      setTotalItems(response.totalItems === 0 ? 1 : response.totalItems);

      if (response.data.length) {
        setData(
          response.data.map((element) => {
            return {
              key: element.id,
              id: element.id,
              patient: element.patient,
              patientName:
                convertToTitleCase(element.patient.patient_firstname) +
                " " +
                convertToTitleCase(element.patient.patient_lastname),
              patientEmail: element.patient.patient_email.toLowerCase(),
              appointmentDuration: element.appt_duration,
              patientType: element.is_follow_up ? "Follow Up" : "New",
              appointmentType: element.type,
              isRescheduled: element.is_reschedule ? "Yes" : "No",
              appointmentStatus: element.appt_status_value,
              clinician: element.column_heading,
              appointmentData: element.appt_visit_start_date_time,
            };
          })
        );
      }
    } catch (e) {
      console.log("failed to load data", e);
      Modal.confirm({
        title: "Error Message",
        content: "Oops! Error in Fetching data",
        okText: "Ok",
        cancelButtonProps: { style: { display: "none" } },
        onOk: async () => {
          console.log("Error in Fetching data");
        },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
  };

  const handleFilter = () => {
    setIsFilterVisible(true);
  };

  const handleClose = () => {
    setIsFilterVisible(false);
  };

  return (
    <div id="dashboard-container" className="continerStyling">
      <>
        <div className="row styleFilterRow">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: 5 }}>
              <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>
                Total Bookings
              </span>
              <Tag>
                <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : totalItems}</strong>
              </Tag>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span className="dateStyling" style={{ marginRight: "10px" }}>
                Filter
              </span>
              <div style={{ position: "relative" }}>
                <FilterOutlined
                  style={{ marginRight: "10px", fontSize: "18px", marginTop: "5px", cursor: "pointer" }}
                  ref={filterButtonRef}
                  onClick={handleFilter}
                />

                {isFilterVisible && (
                  <div style={{ position: "absolute", top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}>
                    <Card
                      title="Filter Options"
                      bordered={true}
                      extra={
                        <Button type="Close" onClick={handleClose}>
                          <CloseCircleOutlined />
                        </Button>
                      }
                    >
                      <FilterComponent
                        searchField={searchField}
                        setSearchField={setSearchField}
                        selectedEntityOption={selectedEntityOption}
                        setSelectedEntityOption={setSelectedEntityOption}
                        selectedAppointmentTypeOption={selectedAppointmentTypeOption}
                        setSelectedAppointmentTypeOption={setSelectedAppointmentTypeOption}
                        selectedAppointmentStatusOption={selectedAppointmentStatusOption}
                        setSelectedAppointmentStatusOption={setSelectedAppointmentStatusOption}
                        from={"Booking"}
                      />
                    </Card>
                  </div>
                )}
              </div>
              <span className="dateStyling">{momentFormatStartDate + " to " + momentFormatEndDate}</span>
              <span ref={buttonRef} onClick={handleToggleDatePicker} className="marginAdjustCalendarToggleButton">
                {showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}
              </span>
              {showDatePicker && (
                <div
                  className="date-picker-container"
                  ref={datePickerRef}
                  style={{
                    position: "absolute",
                    zIndex: "9999",
                    top: "10.5rem",
                    display: "flex",
                    justifyContent: "end",
                    right: "30px",
                  }}
                >
                  <DateRangePicker ranges={dateRange} onChange={handleDateChange} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Spin spinning={loading}>
          <Table
            dataSource={data}
            pagination={{
              current: currentPage,
              pageSize: pageLimit,

              total: totalItems,
            }}
            bordered={true}
            onChange={handleTableChange}
          >
            <Column
              title="Date"
              dataIndex="appointmentData"
              key="appointmentData"
              width={130}
              render={(date) => (
                <>
                  <div>{date ? formatDateAndTimeInlocal(date) : " "}</div>
                </>
              )}
            />

            <Column
              title="Patient Name "
              key="patientCombined"
              render={(record) => (
                <div>
                  <strong>{record.patientName}</strong>
                  <div>{record.patientEmail}</div>
                </div>
              )}
            />

            <Column title="Physician" dataIndex="clinician" key="clinician" />

            <Column title="Type" dataIndex="patientType" key="patientType" />
            <Column
              title="Appointment For"
              dataIndex="appointmentType"
              key="appointmentType"
              render={(appointmentType) => <div>{capitalizeFirstLetter(appointmentType)}</div>}
            />

            <Column title="Duration (Minutes)" dataIndex="appointmentDuration" key="appointmentDuration" width={180} />
            <Column title="Status" dataIndex="appointmentStatus" key="appointmentStatus" />

            <Column title="Rescheduled" dataIndex="isRescheduled" key="isRescheduled" />
          </Table>
        </Spin>
        <Modal
          title={modalTitle}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          maskClosable={true}
          centered={true}
          // closeIcon={<CloseOutlined onClick={() => setIsSubmitting(false)} />}
          style={{ minHeight: "300px", fontSize: "1rem" }}
        >
          {modalComponent}
        </Modal>
      </>
    </div>
  );
}

export default BookingDetails;
