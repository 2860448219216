import React, { useState, useEffect } from "react";
import "../styles/Details.css";
import { Modal, Table, Spin, Tag, Button, Input } from "antd";
import useDebounce from '../hooks/useDebounce';
import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";
import { getInsuranceMappingList } from '../services/ParticipationMatrixService';
import { notify } from "../utils/notify";
const { Column } = Table;
const { Search } = Input;

function ParticipationMatrixInsuraceMapping() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination.current);
    setPageLimit(pagination.pageSize);
    setData([]);
    data.length = 0;
    loadData(pagination.current, pagination.pageSize);
  };

  const debouncedSearchValue = useDebounce(searchTerm);

  useEffect(() => {
    setCurrentPage(1);
    setPageLimit(50);
    setData([])
    data.length = 0;
    loadData(1, 50);
  }, [debouncedSearchValue]);

  async function loadData(pageNo, pageSize) {
    const input = {};

    input["page"] = pageNo;
    input["limit"] = pageSize;

    if (searchTerm !== "") {
      input["search"] = searchTerm;
    }

    try {
      setLoading(true);
      const results = await getInsuranceMappingList(input);
      setTotalItems(results?.pagination?.totalRecords)
      if (results.data !== null) {
        setData(
          results.data.map((data) => {
            return {
              insuranceName: data?.insuranceName,
              amdCarrierName: data?.amdCarrierName,
              amdCarrierId: data?.amdCarrierId,
              soharPayerId: data?.soharPayerId
            }
          })
        )
      }
    } catch (e) {
      console.error(e);
      Modal.confirm({
        title: "Error Message",
        content: "Oops! Error in Fetching data",
        okText: "Ok",
        onOk: async () => {
          console.error("Error in Fetching forms temp data");
        },
        cancelButtonProps: { style: { display: "none" } },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
  }

  const generateExcel = (data) => {
    const columnsToInclude = [
      "insuranceName",
      "amdCarrierName",
      "amdCarrierId",
      "soharPayerId"
    ];

    // Filter the data to include only the specified columns
    const filteredData = data.map((item) => {
      // Create a new object with only the specified columns
      const filteredItem = {};
      columnsToInclude.forEach((column) => {
        filteredItem[column] = item[column];
      });
      return filteredItem;
    });

    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    return new Blob([excelBuffer], { type: filetype });
  };

  const handleDownloadMuster = async () => {
    const records = [...data];
    try {
      let blobData, fileExtension;
      blobData = generateExcel(records);
      fileExtension = ".xlsx";

      //  save the pdf to user's local device
      saveAs(blobData, 'test_assessment' + fileExtension);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      notify("Downloaded!", "Please check your downloads!");
    }
  };

  return (
    <div id="dashboard-container">
      <div className="row styleFilterRow">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: 5 }}>
            <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10, marginTop: 13 }}>
              Total Insurances
            </span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{totalItems}</strong>
            </Tag>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <label htmlFor="search" className="labelStylingFilter" style={{ fontWeight: "bold" }}>
              Search Insurance:
            </label>
            <Search
              id="search"
              placeholder="Search by Insurance Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onSearch={handleSearch}
              allowClear
              style={{ width: "25vw" }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Button onClick={handleDownloadMuster}>Export a Excel</Button>
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <Table
          dataSource={data}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: pageLimit,
          }}
          bordered={true}
          onChange={handleTableChange}
          scroll={{ y: "65vh" }}
        >
          <Column
            title="Insurance Name"
            dataIndex="insuranceName"
            key="insuranceName"
            width={260}
            render={(text, record) => (
              <div>
                <strong>{record?.insuranceName}</strong>
              </div>
            )}
          />
          <Column
            title="AMD Insurance Name"
            dataIndex="amdCarrierName"
            key="amdCarrierName"
            width={200}
            render={(text, record) => (
              <div>
                {record?.amdCarrierName}
              </div>
            )}
          />
          <Column
            title="AMD Carrier ID"
            dataIndex="amdCarrierId"
            key="amdCarrierId"
            width={200}
            render={(text, record) => (
              <div>
                {record?.amdCarrierId}
              </div>
            )}
          />
          <Column
            title="SoharHealth Payer ID"
            dataIndex="soharPayerId"
            key="soharPayerId"
            width={200}
            render={(text, record) => (
              <div>
                {record?.soharPayerId}
              </div>
            )}
          />
        </Table>
      </Spin>
    </div>
  )
}

export default ParticipationMatrixInsuraceMapping;