import BaseService from './BaseService';

export default class PartnerAppointmentService extends BaseService {
    async getAthenaAppointment(startDate, endDate, pageNo, pageSize, searchText, appointmentStatus, isScoredOnly, isAvailableInAmd) {
        const endpoint = '/api/atheanPartnerAppointmentDetails';
        
        // Configure parameters
        const config = {
            params: {
                fromDate: startDate.toDateString(),
                toDate: endDate.toDateString(),
                pageNo: pageNo,
                limit: pageSize,
                searchText: searchText,
                appointmentStatus: appointmentStatus.toString(),
                isScored: isScoredOnly ? isScoredOnly : null,
                isAvailableInAmd: isAvailableInAmd ? isAvailableInAmd : null,
            }
        };

        try {
            return await this.get(endpoint, config);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log("No appointments found:", error);
                return { data: [] };
            }
            console.error("Error fetching Athena appointments:", error);
            throw new Error('Could not fetch appointments.');
        }
    }
}

// Create a singleton instance
const partnerAppointmentService = new PartnerAppointmentService();

// Export the methods from the instance
export const getAthenaAppointment = partnerAppointmentService.getAthenaAppointment.bind(partnerAppointmentService);