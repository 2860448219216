import BaseService from './BaseService';

export default class StateService extends BaseService {
    async getStates() {
        try {
            return await this.get('/api/states');
        } catch (error) {
            console.error("Error fetching states:", error);
            throw new Error('Could not fetch states.');
        }
    }

    async insertStates(data) {
        try {
            return await this.post('/api/states', data);
        } catch (error) {
            console.error("Error inserting states:", error);
            
            // Extract error message if available
            let errorMessage = 'Could not insert states.';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error.message;
            }
            
            throw new Error(errorMessage);
        }
    }
}

// Create a singleton instance
const stateService = new StateService();

// Export the methods from the instance
export const getStates = stateService.getStates.bind(stateService);
export const insertStates = stateService.insertStates.bind(stateService);