import BaseService from './BaseService'
import { json } from 'react-router-dom'
import { BACKEND_URL } from '../utils/urls'

export default class ClinicianService extends BaseService {
  constructor() {
    super()
  }

  async getAllData(pageNo, pageSize, intent, data) {
    try {
      const url = `/api/providers`
      
      // Create axios config with params for pagination
      const config = {
        params: {
          pageNo: pageNo,
          limit: pageSize
        }
      }
      
      // Create the payload for the request body
      const payload = {
        intent: intent,
        payload: data,
        context: null
      }

      // Use the updated post method with proper config
      const responseData = await this.post(url, payload, config)
      
      // Return the data directly without additional checks
      // since error handling happens in the catch block
      return responseData || { data: [] }
    } catch (error) {
      console.error("Error in getAllData:", error)
      let errorMessage = ''
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error.message
      }

      throw new Error(errorMessage || 'An error occurred while fetching provider data')
    }
  }

  async getSpecialization() {
    try {
      return await this.get(`/api/getAllProviderSpecialization`)
    } catch (error) {
      console.error("Error fetching specializations:", error)
      throw json(
        { message: 'Could not fetch Provider Specialization.' },
        {
          status: 500
        }
      )
    }
  }

  async getAgePoints() {
    try {
      return await this.get(`/api/getAllProviderAgePoints`)
    } catch (error) {
      console.error("Error fetching age points:", error)
      throw json(
        { message: 'Could not fetch Provider AgePoints.' },
        {
          status: 500
        }
      )
    }
  }

  async getTreatmentPreferences() {
    try {
      return await this.get(`/api/getAllProviderTreatmentPreference`)
    } catch (error) {
      console.error("Error fetching treatment preferences:", error)
      throw json(
        { message: 'Could not fetch Provider Treatments.' },
        {
          status: 500
        }
      )
    }
  }
}

// Create a singleton instance to export
const clinicianService = new ClinicianService();

// Export the methods from the instance with proper binding
export const getAllData = clinicianService.getAllData.bind(clinicianService);
export const getSpecialization = clinicianService.getSpecialization.bind(clinicianService);
export const getAgePoints = clinicianService.getAgePoints.bind(clinicianService);
export const getTreatmentPreferences = clinicianService.getTreatmentPreferences.bind(clinicianService);
