import BaseService from './BaseService';
import axios from 'axios';
import { FORMS_URL } from '../utils/urls';

export default class FormsSubmissionServices extends BaseService {
    // Helper method for GraphQL requests since they use a different endpoint and format
    async executeGraphQLQuery(query, variables = {}, customHeaders = {}) {
        const bodyContent = {
            query: query,
            variables: variables
        };

        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            vary: 'Origin',
            ...customHeaders
        };

        try {
            const response = await axios.post(FORMS_URL, bodyContent, { headers });
            
            if (response.status === 200 && response.data) {
                return response.data;
            } else {
                throw new Error('Could not fetch data from GraphQL endpoint.');
            }
        } catch (error) {
            console.error("GraphQL request error:", error);
            throw new Error('Could not fetch data from GraphQL endpoint.');
        }
    }

    async getFormsDetails() {
        const query = `query ObGynFormSubmissions($clinic: String) {
            ObGynFormSubmissions(clinic: $clinic) {
                id clinic firstName lastName email phone insurances
                clinician contactMethod createdAt babyDay epdsScore
                phq9Score gad7Score gdsScore phq9Answers gad7Answers
                epdsAnswers gdsAnswers anxietyAnswers pregnancyStatus dob __typename
            }
        }`;

        const headersList = {
            "authority": "api.space.hellolunajoy.com",
            "accept": "application/graphql+json, application/json",
            "accept-language": "en-US,en;q=0.9,fr;q=0.8",
            "content-type": "application/json",
            "cookie": "_ga=GA1.1.1257599107.1677599362; _pin_unauth=dWlkPU16ZGhNelF6TWpVdE1qTTJZaTAwWVRRMkxUazVZVEV0TVdFelpUUmhOelUyTldWaQ; _hjSessionUser_2855504=eyJpZCI6ImZhNzBkNTRmLWYyOTAtNTkzOC1hNTVjLTJhODI0MTg4MzQ4ZCIsImNyZWF0ZWQiOjE2Nzc1OTkzNjIxNTksImV4aXN0aW5nIjp0cnVlfQ==; _fbp=fb.1.1684958927616.878471502; _gcl_au=1.1.695130156.1687767127; accessToken=c359dbd1-1b80-4e64-9ad3-6d7cf1aa4d7d; _derived_epik=dj0yJnU9MmJaQnVneFZmTkNyRGJIV3BManphMU9MVzUtRUoyOEQmbj1NMVFGMUlrekFRSWZ0TlIwSzd2WEZBJm09MSZ0PUFBQUFBR1RBemFFJnJtPTEmcnQ9QUFBQUFHVEF6YUUmc3A9Mg; _ga_26JXKRKYJ9=GS1.1.1690357150.11.0.1690357161.49.0.0; _ga_6ZEYLCN6RE=GS1.1.1690357151.110.0.1690357162.49.0.0; _ga_1X0XQRMB4F=GS1.1.1690357151.109.0.1690357162.0.0.0",
            "origin": "https://space.hellolunajoy.com",
            "referer": "https://space.hellolunajoy.com/",
            "user-agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36"
        };

        return this.executeGraphQLQuery(query, {}, headersList);
    }

    async getFormsDetailsWithFilter(input) {
        const query = `query FormSubmissionsCountByFilters($input: FormSubmissionsByFiltersInput) { 
            FormSubmissionsCountByFilters (input: $input) { 
                currentPage 
                data {
                    clinic firstName lastName email phone dob assessmentCount 
                    assessmentHistory {
                        id clinic firstName lastName email phone insurances 
                        dob clinician contactMethod createdAt babyDay epdsScore 
                        phq9Score gad7Score epdsAnswers anxietyAnswers phq9Answers 
                        gad7Answers pregnancyStatus gdsScore gdsAnswers type __typename
                    } 
                    __typename
                } 
                totalRecords 
            }
        }`;

        return this.executeGraphQLQuery(query, { input });
    }

    async getFormsTempDetailsWithFilter(input) {
        const query = `query FormTempSubmissionsCountByFilters($input: FormSubmissionsByFiltersInput) { 
            FormTempSubmissionsCountByFilters (input: $input) { 
                currentPage 
                data {
                    id firstName lastName email phone dob mismatchReason __typename
                } 
                totalRecords 
            }
        }`;

        return this.executeGraphQLQuery(query, { input });
    }

    async reconcileFormTempSubmissions(input) {
        const query = `mutation reconcileFormTempSubmissions($input: reconcileFormTempSubmissionsInput!) {
            reconcileFormTempSubmissions(input: $input)
        }`;

        return this.executeGraphQLQuery(query, { input });
    }

    async getForms() {
        try {
            return await this.get('/api/assessments');
        } catch (error) {
            console.error("Error fetching forms:", error);
            throw new Error('Could not fetch assessments.');
        }
    }

    async getOneForm(id) {
        try {
            return await this.get(`/api/assessments/${id}`);
        } catch (error) {
            console.error("Error fetching form:", error);
            throw new Error('Could not fetch assessment details.');
        }
    }
}

// Create a singleton instance
const formsSubmissionServices = new FormsSubmissionServices();

// Export the methods from the instance
export const getForms = formsSubmissionServices.getForms.bind(formsSubmissionServices);
export const getOneForm = formsSubmissionServices.getOneForm.bind(formsSubmissionServices);
export const getFormsDetails = formsSubmissionServices.getFormsDetails.bind(formsSubmissionServices);
export const getFormsDetailsWithFilter = formsSubmissionServices.getFormsDetailsWithFilter.bind(formsSubmissionServices);
export const getFormsTempDetailsWithFilter = formsSubmissionServices.getFormsTempDetailsWithFilter.bind(formsSubmissionServices);
export const reconcileFormTempSubmissions = formsSubmissionServices.reconcileFormTempSubmissions.bind(formsSubmissionServices);