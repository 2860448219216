import { BACKEND_URL } from "../utils/urls";
import BaseService from './BaseService';
import { getCache, setCache, invalidateCache } from '../utils/cacheUtils';

// Define a cache key for clinics complete data
const CLINICS_COMPLETE_CACHE_KEY = 'clinics_complete_data';

export default class ClinicsService extends BaseService {
    async getClinics(clinic, alias, typeOfScreening, typeOfEhr) {
        const url = `/api/carePartners`;
        
        // Create axios config with params
        const config = {
            params: {
                clinic: clinic || null,
                typeOfScreening: typeOfScreening || null,
                typeOfEhr: typeOfEhr || null,
                alias: alias || null
            }
        };
        
        try {
            return await this.get(url, config);
        } catch (error) {
            console.error("Error in getClinics:", error);
            return [];
        }
    }

    async getClinicsComplete(clinic, alias, typeOfScreening, typeOfEhr) {
        // Create a unique cache key if filters are applied
        let cacheKey = CLINICS_COMPLETE_CACHE_KEY;
        if (clinic || alias || typeOfScreening || typeOfEhr) {
            cacheKey = `${CLINICS_COMPLETE_CACHE_KEY}_${clinic || ''}_${alias || ''}_${typeOfScreening || ''}_${typeOfEhr || ''}`;
        }
        
        // Try to get data from cache first
        const cachedData = getCache(cacheKey);
        if (cachedData) {
            return cachedData;
        }
        
        // If not in cache or expired, fetch from API
        const url = `/api/carePartnersComplete`;
        
        // Create axios config with params
        const config = {
            params: {
                clinic: clinic || null,
                typeOfScreening: typeOfScreening || null,
                typeOfEhr: typeOfEhr || null,
                alias: alias || null
            }
        };
        
        try {
            const data = await this.get(url, config);
            // Store in cache and return
            return setCache(cacheKey, data);
        } catch (error) {
            console.error("Error in getClinicsComplete:", error);
            return [];
        }
    }

    async getClinicDetails(id) {
        const url = `/api/carePartners/${id}`;
        return await this.get(url);
    }

    async deleteClinic(id) {
        const url = `/api/carePartners/${id}`;
        try {
            const result = await this.delete(url);
            // Invalidate the cache when a clinic is deleted
            invalidateCache(CLINICS_COMPLETE_CACHE_KEY);
            return result;
        } catch (error) {
            console.error("Error deleting clinic:", error);
            throw error;
        }
    }

    async updateClinic(id, clinicData) {
        const url = `/api/carePartners/${id}`;
        
        // Create config with proper headers
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        
        try {
            const result = await this.put(url, clinicData, config);
            // Invalidate the cache when a clinic is updated
            invalidateCache(CLINICS_COMPLETE_CACHE_KEY);
            return result;
        } catch (error) {
            console.error("Error updating clinic:", error);
            throw error;
        }
    }

    async insertClinic(data) {
        const url = `/api/carePartners`;
        
        // Create config with proper headers
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        
        try {
            const result = await this.post(url, data, config);
            // Invalidate the cache when a new clinic is inserted
            invalidateCache(CLINICS_COMPLETE_CACHE_KEY);
            return result;
        } catch (error) {
            console.error("Error inserting clinic:", error);
            throw error;
        }
    }

    async checkClinicCodeExists(code) {
        const url = `/api/carePartners/code/${code}`;
        try {
            await this.get(url);
            return true; // If the request succeeds, the clinic code exists
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return false; // If 404, the clinic code doesn't exist
            }
            throw error; // For other errors, pass them along
        }
    }
}

// Create a singleton instance to export
const clinicsService = new ClinicsService();

// Export the methods from the instance
export const getClinics = clinicsService.getClinics.bind(clinicsService);
export const getClinicsComplete = clinicsService.getClinicsComplete.bind(clinicsService);
export const getClinicDetails = clinicsService.getClinicDetails.bind(clinicsService);
export const deleteClinic = clinicsService.deleteClinic.bind(clinicsService);
export const updateClinic = clinicsService.updateClinic.bind(clinicsService);
export const insertClinic = clinicsService.insertClinic.bind(clinicsService);