import BaseService from './BaseService';

export default class PartnerPatientService extends BaseService {
  async getPartnerPatient(pageNo, pageSize, referralPlatform, clinicName, searchText, startDate, endDate) {
    const endpoint = '/api/partnerPatients';
    
    // Configure parameters
    const config = {
      params: {
        pageNo: pageNo,
        limit: pageSize,
        referralPlatform: referralPlatform ? referralPlatform : null,
        clinicName: clinicName ? clinicName : null,
        searchText: searchText,
        fromDate: startDate.toDateString(),
        toDate: endDate.toDateString(),
      }
    };

    try {
      return await this.get(endpoint, config);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { data: [] };
      }
      console.error("Error fetching partner patients:", error);
      throw new Error('Could not fetch partner patients.');
    }
  }
  
  async getPartnerPatientSummary(pageNo, pageSize, startDate, endDate) {
    const endpoint = '/api/partnerPatientSummary';
    
    // Configure parameters
    const config = {
      params: {
        pageNo: pageNo,
        limit: pageSize,
        fromDate: startDate.toDateString(),
        toDate: endDate.toDateString(),
      }
    };

    try {
      return await this.get(endpoint, config);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { data: [] };
      }
      console.error("Error fetching partner patient summary:", error);
      throw new Error('Could not fetch partner patient summary.');
    }
  }  

  async getUniqueClinicName() {
    const endpoint = '/api/getUniqueClinicNames';
    
    try {
      return await this.get(endpoint);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { data: [] };
      }
      console.error("Error fetching unique clinic names:", error);
      throw new Error('Could not fetch unique clinic names.');
    }
  }
}

// Create a singleton instance
const partnerPatientService = new PartnerPatientService();

// Export the methods from the instance
export const getPartnerPatient = partnerPatientService.getPartnerPatient.bind(partnerPatientService);
export const getPartnerPatientSummary = partnerPatientService.getPartnerPatientSummary.bind(partnerPatientService);
export const getUniqueClinicName = partnerPatientService.getUniqueClinicName.bind(partnerPatientService);
