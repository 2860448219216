import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, DatePicker, Checkbox, Spin, Modal, Switch, Upload, Row, Col, Card } from 'antd'
import { notify } from '../utils/notify'
import { getStates } from '../services/StateService'
import { UploadOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { validateForm } from '../utils/validateForms'
import { faxFrequency, faxBookingTypeSync, clinicPartnerTypes, faxSequencesV2, faxFrequency2, faxSequencesV3 } from '../utils/constants';
import { insertClinic } from '../services/ClinicsService'
import { getAllGHLTagMaster } from '../services/GHLTagMasterService'
import { updateStateOfTagMaster } from '../utils/GHLTagMasterUpdateState'
import ClinicsService from '../services/ClinicsService'
import useDebounce from '../hooks/useDebounce'

const { Option } = Select

const typesOfScreen = [
  { value: 'Obgyn', text: 'EPDS' },
  { value: 'PHQ9-GAD7', text: 'PHQ9-GAD7' },
  { value: 'Geriatric', text: 'GDS' }
]

const ehrSystem = ['None', 'AthenaHealth', 'eCW', 'eCW - Femwell', 'Epic', 'Cerner', 'AllScripts']
const typeOfIntegration = [
  { value: 'Sync Data', text: 'Yes' },
  { value: 'None', text: 'No' }
]

const CarePartnerForm = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [ehrName, setEhrName] = useState('')
  const [states, setStates] = useState([])
  const [sourceTechnologyType, setSourceTechnologyType] = useState([])
  const [sourceTechnology, setSourceTechnology] = useState([])
  const [sourceOrganizationType, setSourceOrganizationType] = useState([])
  const [sourceOrganizationName, setSourceOrganizationName] = useState([])
  const [specialty, setSpecialty] = useState([])
  const [fileList, setFileList] = useState([])
  const [isFaxScheduled, setIsFaxScheduled] = useState(false);
  const clinicsService = new ClinicsService();
  const [clinicCodeValue, setClinicCodeValue] = useState('')
  const [validatingClinicCode, setValidatingClinicCode] = useState(false)
  const [clinicCodeExists, setClinicCodeExists] = useState(false)
  
  // Debounce clinic code value
  const debouncedClinicCode = useDebounce(clinicCodeValue, 500)

  // Check for clinic code existence when debounced value changes
  useEffect(() => {
    // Don't check if clinic code is empty or less than 5 characters
    if (!debouncedClinicCode || debouncedClinicCode.length < 5) {
      setClinicCodeExists(false);
      return;
    }
    
    const checkClinicCode = async () => {
      setValidatingClinicCode(true);
      try {
        const exists = await clinicsService.checkClinicCodeExists(debouncedClinicCode);
        setClinicCodeExists(exists);
        
        // Manually trigger validation on the clinicCode field after we get the API response
        if (exists) {
          // Force validation to show the error message immediately
          form.validateFields(['clinicCode']);
        }
      } catch (error) {
        console.error('Error checking clinic code:', error);
      } finally {
        setValidatingClinicCode(false);
      }
    };
    
    checkClinicCode();
  }, [debouncedClinicCode, form]);

  useEffect(() => {
    setLoading(true)
    loadData()
  }, [])

  const loadData = async () => {
    const state = await getStates()
    setStates([{ code: '--', title: 'None' }, ...state])
    const ghlTagMasters = await getAllGHLTagMaster()
    updateStateOfTagMaster(
      ghlTagMasters,
      setSourceTechnologyType,
      setSourceTechnology,
      setSourceOrganizationType,
      setSourceOrganizationName,
      setSpecialty
    )
    setLoading(false)
  }
  const handleSwitchChange = (checked) => {
    setIsFaxScheduled(checked); 
  };

  const onFinish = async (data) => {
    setLoading(true);
    const errors = validateForm(data)
    if (Object.keys(errors).length === 0) {
      // Retrieve the user object from local storage
      const user = JSON.parse(localStorage.getItem('user'))

      const formData = new FormData()
      formData.append('name', data.name)
      formData.append('address', data.address)
      formData.append('currentUser', user.name)
      formData.append('clinicCode', data.clinicCode)
      fileList?.length && data?.logo?.fileList.length > 0 && formData.append('logo', data.logo.fileList[0].originFileObj)
      data.faxNumber && formData.append('faxNumber', data.faxNumber)
      data.faxSequence && formData.append('faxSequence', data.faxSequence)
      // data.faxFrequency && formData.append('faxFrequency', data.faxFrequency)
      data.isFaxScheduled && formData.append('isFaxScheduled', data.isFaxScheduled)
      data.clinicans && formData.append('clinicans', data.clinicans)
      data.typesOfScreen && formData.append('typesOfScreen', data.typesOfScreen)
      data.ehr && formData.append('ehr', data.ehr)
      data.ehrId && formData.append('ehrId', data.ehrId)
      formData.append('typesOfIntegration', data.typesOfIntegration ? 'Sync Data' : 'None')
      data.referralLink && formData.append('referralLink', data.referralLink)
      data.allowReferral && formData.append('allowReferral', data.allowReferral)
      data.alias && formData.append('alias', data.alias)
      data.streetName && formData.append('streetName', data.streetName)
      data.sourceTechnology && formData.append('sourceTechnology', data.sourceTechnology)
      data.sourceTechnologyType && formData.append('sourceTechnologyType', data.sourceTechnologyType)
      data.sourceOrganizationType && formData.append('sourceOrganizationType', data.sourceOrganizationType)
      data.sourceOrganizationName && formData.append('sourceOrganizationName', data.sourceOrganizationName)
      data.specialty && formData.append('specialty', data.specialty)
      // data.faxBookingTypeSync && formData.append('faxBookingTypeSync', data.faxBookingTypeSync)
      data.partnerType && formData.append('partnerType', data.partnerType)
      if (isFaxScheduled) {
        data.faxBookingTypeSync && formData.append('faxBookingTypeSync', data.faxBookingTypeSync)
        data.faxFrequency && formData.append('faxFrequency', data.faxFrequency)

        data.referralRecepientReportFrequency && formData.append('referralRecepientReportFrequency', data.referralRecepientReportFrequency)

        data.assessmentScreeningSequence && formData.append('assessmentScreeningSequence', data.assessmentScreeningSequence)
        data.assessmentScreeningFrequency && formData.append('assessmentScreeningFrequency', data.assessmentScreeningFrequency)

        data.referralStatusSequence && formData.append('referralStatusSequence', data.referralStatusSequence)
        data.referralStatusFrequency && formData.append('referralStatusFrequency', data.referralStatusFrequency)
      }
      try {
        await insertClinic(formData)
        notify(`"${data.name}" Clinic Added Successfully!`)
        navigate('/clinic')
      } catch (error) {
        console.log(error)
        Modal.confirm({
          title: 'Error',
          content: (
            <div>
              <p>Oops! Failed to add care partner details.</p>
              <p>{error.message}</p>
              {error.message === 'Validation error' ? (
                <>
                  <div>* Clinic name already exist in database.</div>
                </>
              ) : null}
            </div>
          ),
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
      setLoading(false)
    } else {
      setLoading(false)
      const errorMessages = Object.values(errors).map((error, index) => {
        if (typeof error == 'object') {
          return error.map((err, i) => <p style={{ marginBottom: 0 }} key={`${i}_${index}`}>* {err}</p>);
        } else {
          return (<div key={index}>* {error}</div>)
        }
      })
      Modal.confirm({
        title: 'Error Message',
        content: (
          <div>
            {!errors.clinicans && <p>Oops! Can not update data due to the following errors:</p>}
            {errorMessages}
          </div>
        ),
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
    }

    return true
  }

  const goBack = () => {
    navigate('/clinic')
  }

  const formItemLayout = {
    // Remove horizontal layout constraints
  }

  // Add a specific layout for fax scheduling section with longer labels
  const faxSchedulingLayout = {
    // Remove horizontal layout constraints for fax section
  }

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0, img.width, img.height)

        const width = canvas.width
        const height = canvas.height

        resolve({ width, height })
      }

      img.onerror = () => {
        reject('Error loading image')
      }

      img.src = URL.createObjectURL(file)
    })
  }

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    if (file.size > 90 * 1024) {
      onError('File size should be less than 90kb')
      setFileList([])
      Modal.confirm({
        title: 'Error',
        content: (
          <div>
            <p>File size should be less than 90kb</p>
          </div>
        ),
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
    } else {
      const dimensions = await getImageDimensions(file)
      if (dimensions.width <= 750 && dimensions.height <= 500) {
        onSuccess('ok')
        setFileList([file])
      } else {
        onError('This image does not meet the required resolution.')
        setFileList([])
        Modal.confirm({
          closable: false,
          title: 'Error',
          content: (
            <div>
              <p>This image does not meet the required resolution.</p>
            </div>
          ),
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
    }
  }

  // Clinic code validator
  const validateClinicCode = (_, value) => {
    if (!value) return Promise.resolve(); // Let the required validator handle empty value
    
    // Simplified validation - directly check if clinicCodeExists state is true
    if (clinicCodeExists && value.length >= 5) {
      return Promise.reject(new Error('This clinic code already exists. Please use a different code.'));
    }
    
    return Promise.resolve();
  };

  // Handle input change for clinic code
  const handleClinicCodeChange = (e) => {
    const value = e.target.value;
    setClinicCodeValue(value);
    
    // Reset the existence status when the user changes the input
    if (clinicCodeExists) {
      setClinicCodeExists(false);
    }
  };

  return (
    <Spin spinning={loading || validatingClinicCode}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%',
        padding: '0 16px'
      }}>
        <div style={{ 
          width: '100%',
          maxWidth: '1200px'
        }}>
          <Form 
            form={form} 
            onFinish={onFinish} 
            encType="multipart/form-data" 
            layout="vertical"
            labelAlign="left"
            colon={false}
            requiredMark="optional"
          >
            <Card 
              title="Care Partner Information" 
              style={{ 
                marginBottom: 24, 
                borderRadius: '8px', 
                border: '1px solid #d9d9d9',
                backgroundColor: '#f9f9ff'
              }}
              headStyle={{ 
                fontSize: '18px', 
                fontWeight: 'bold',
                backgroundColor: '#eceeff',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
              }}
            >
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label="Clinic/Referring Party"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the care partner name.'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="clinicCode"
                    label="Clinic Code (Cannot be updated later)"
                    validateStatus={clinicCodeExists ? "error" : undefined}
                    help={clinicCodeExists ? "This clinic code already exists. Please use a different code." : undefined}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the clinic code.'
                      },
                      {
                        min: 5, 
                        message: 'Clinic code must be at least 5 characters long.'
                      },
                      {
                        validator: validateClinicCode
                      }
                    ]}
                  >
                    <Input 
                      onChange={handleClinicCodeChange}
                      suffix={validatingClinicCode ? <Spin size="small" /> : null}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item name="specialty" label="Specialty">
                    <Select
                      placeholder="Select a specialty"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {specialty.map((element) => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
              <Col xs={24} md={12}>
                  <Form.Item name="streetName" label="Street name">
                    <Input placeholder="Enter street name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="address"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a state.'
                      }
                    ]}
                  >
                    <Select
                      placeholder="Select a state"
                      mode="multiple"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {states.map((state) => (
                        <Option key={state.code} value={state.code}>
                          {state.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
              <Col xs={24} md={12}>
                  <Form.Item name="partnerType" label="Clinic Partner Type">
                    <Select placeholder="Select a clinic partner type">
                      {clinicPartnerTypes?.map((type) => (
                        <Option key={type.value} value={type.value}>
                          {type.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="typesOfScreen" label="Type of screener">
                    <Select placeholder="Select a screener type" mode="multiple">
                      {typesOfScreen.map((screener) => (
                        <Option key={screener.value} value={screener.value}>
                          {screener.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item name="referralLink" label="Referral Link">
                    <Input placeholder="Enter referral link" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="clinicans" label="Clinicians">
                    <Select mode="tags" placeholder="Add clinicians" tokenSeparators={[',']} />
                  </Form.Item>
                </Col>
              </Row>
              
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item name="faxNumber" label="Fax number">
                    <Input addonBefore="+1" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="logo" label="Logo">
                    <Upload
                      maxCount={1}
                      fileList={fileList}
                      onRemove={(value) => {
                        setFileList([])
                      }}
                      customRequest={handleFileUpload}
                      listType="text"
                      accept=".jpg,.jpeg,.png"
                    >
                      <Button icon={<UploadOutlined />}>Upload Logo</Button>
                      <div>
                        <small>The image size must be less than 90 KB, and dimensions less than 750 x 500 pixels.</small>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item name="isFaxScheduled" label="Schedule fax">
                    <Switch onChange={handleSwitchChange} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {/* Fax Scheduling Section */}
            {isFaxScheduled && (
              <Card 
                title="Fax Scheduling Configuration" 
                style={{ 
                  marginBottom: 24, 
                  borderRadius: '8px', 
                  border: '1px solid #d9d9d9',
                  backgroundColor: '#f9fff9'
                }}
                headStyle={{ 
                  fontSize: '18px', 
                  fontWeight: 'bold',
                  backgroundColor: '#ecffec',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px'
                }}
              >
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="faxBookingTypeSync"
                      label="Review Report Sequence"
                      {...faxSchedulingLayout}
                      rules={[
                        {
                          required: isFaxScheduled,
                          message: "Please select review report sequence",
                        },
                      ]}
                    >
                      <Select placeholder="Select a review report sequence">
                        {faxBookingTypeSync?.map((type) => (
                          <Option key={type.value} value={type.value}>
                            {type.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.faxBookingTypeSync !== currentValues.faxBookingTypeSync
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("faxBookingTypeSync") ? (
                          <Form.Item
                            name="faxFrequency"
                            label="Review Report Frequency"
                            {...faxSchedulingLayout}
                            rules={[
                              {
                                required: isFaxScheduled,
                                message: "Please select review report frequency",
                              },
                            ]}
                          >
                            <Select placeholder="Select a review report frequency">
                              {faxFrequency?.map((freq) => (
                                <Option key={freq.value} value={freq.value}>
                                  {freq.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item 
                      name="referralRecepientReportFrequency" 
                      label="Referral Recipient Report Frequency"
                      {...faxSchedulingLayout}
                      rules={[{
                        required: isFaxScheduled,
                        message: 'Please select referral recipient report frequency'
                      }]}
                    >
                      <Select placeholder="Select Referral Recipient Report Frequency">
                        {faxFrequency2?.map((freq) => (
                          <Option key={freq.value} value={freq.value}>
                            {freq.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="assessmentScreeningSequence"
                      label="Assessment Screening Sequence"
                      {...faxSchedulingLayout}
                      rules={[
                        {
                          required: isFaxScheduled,
                          message: "Please select assessment screening sequence",
                        },
                      ]}
                    >
                      <Select placeholder="Select an assessment screening sequence">
                        {faxSequencesV3.map((fax) => (
                          <Option key={fax} value={fax}>
                            {fax}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.assessmentScreeningSequence !==
                        currentValues.assessmentScreeningSequence
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("assessmentScreeningSequence") ? (
                          <Form.Item
                            name="assessmentScreeningFrequency"
                            label="Assessment Screening Frequency"
                            {...faxSchedulingLayout}
                            rules={[
                              {
                                required: isFaxScheduled,
                                message: "Please select assessment screening frequency",
                              },
                            ]}
                          >
                            <Select placeholder="Select Assessment Screening Frequency">
                              {faxFrequency?.map((freq) => (
                                <Option key={freq.value} value={freq.value}>
                                  {freq.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="referralStatusSequence"
                      label="Referral Status Sequence"
                      {...faxSchedulingLayout}
                      rules={[
                        {
                          required: isFaxScheduled,
                          message: "Please select referral status sequence",
                        },
                      ]}
                    >
                      <Select placeholder="Select a referral status sequence">
                        {faxSequencesV2.map((fax) => (
                          <Option key={fax} value={fax}>
                            {fax}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.referralStatusSequence !== currentValues.referralStatusSequence
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("referralStatusSequence") ? (
                          <Form.Item
                            name="referralStatusFrequency"
                            label="Referral Status Frequency"
                            {...faxSchedulingLayout}
                            rules={[
                              {
                                required: isFaxScheduled,
                                message: "Please select referral status frequency",
                              },
                            ]}
                          >
                            <Select placeholder="Select referral status frequency">
                              {faxFrequency2?.map((freq) => (
                                <Option key={freq.value} value={freq.value}>
                                  {freq.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            )}

            <Card 
              title="Source Information" 
              style={{ 
                marginBottom: 24, 
                borderRadius: '8px', 
                border: '1px solid #d9d9d9',
                backgroundColor: '#fffcf5'
              }}
              headStyle={{ 
                fontSize: '18px', 
                fontWeight: 'bold',
                backgroundColor: '#fff8e6',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
              }}
            >
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item name="sourceTechnologyType" label="Source technology type">
                    <Select
                      placeholder="Select a source technology type"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {sourceTechnologyType.map((element) => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="sourceTechnology" label="Source technology">
                    <Select
                      placeholder="Select a source technology"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {sourceTechnology.map((element) => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item name="sourceOrganizationType" label="Source organization type">
                    <Select
                      placeholder="Select a source organization type"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {sourceOrganizationType.map((element) => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="sourceOrganizationName" label="Source organization name">
                    <Select
                      placeholder="Select a source organization name"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {sourceOrganizationName.map((element) => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card 
              title="EHR Integration" 
              style={{ 
                marginBottom: 24, 
                borderRadius: '8px', 
                border: '1px solid #d9d9d9',
                backgroundColor: '#fdf9ff'
              }}
              headStyle={{ 
                fontSize: '18px', 
                fontWeight: 'bold',
                backgroundColor: '#f5ecff',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
              }}
            >
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item name="ehr" label="EHR name">
                    <Select
                      placeholder="Select a EHR"
                      onChange={(value) => {
                        setEhrName(value)
                      }}
                    >
                      {ehrSystem.map((element) => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {ehrName && ehrName !== 'None' ? (
                  <Col xs={24} md={12}>
                    <Form.Item name="alias" label="Clinic Name in EHR">
                      <Input />
                      <div>
                        <small>*(Provider the clinic name send by EHR)</small>
                      </div>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>

              {ehrName === 'AthenaHealth' ? (
                <>
                  <div style={{ 
                    marginTop: 16, 
                    marginBottom: 16, 
                    fontWeight: 'bold',
                    padding: '8px 12px',
                    backgroundColor: '#f9f0ff',
                    borderRadius: '4px'
                  }}>
                    AthenaHealth Configuration
                  </div>
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <Form.Item name="ehrId" label="Practice id">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item 
                        name="typesOfIntegration" 
                        label="Enable sync"
                        valuePropName="checked"
                        style={{ marginBottom: 16 }}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <Form.Item 
                        label="Athena referral" 
                        name="allowReferral" 
                        valuePropName="checked"
                      >
                        <Checkbox>Enable Athena referral functionality</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Card>

            {/* Back and Submit Buttons */}
            <Row>
              <Col xs={24} style={{ textAlign: 'right',  marginBottom: 40 }}>
                <Button size="large" onClick={goBack} style={{ marginRight: 10, fontWeight: 'semibold' }}>Back</Button>
                <Button 
                  size="large" 
                  type="primary" 
                  htmlType="submit" 
                  style={{ padding: '0 30px', fontWeight: 'semibold' }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Spin>
  )
}

export default CarePartnerForm
