import BaseService from './BaseService'

export default class GHLTagMasterService extends BaseService {
  async getAllGHLTagMaster() {
    try {
      const url = `/api/ghlTagMasters`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getAllGHLTagMaster:", error);
      throw error;
    }
  }
}

// Create a singleton instance to export
const ghlTagMasterService = new GHLTagMasterService();

// Export the method from the instance
export const getAllGHLTagMaster = ghlTagMasterService.getAllGHLTagMaster.bind(ghlTagMasterService);
