import BaseService from './BaseService';

export default class GHLService extends BaseService {
  async getAllGHLUsers() {
    try {
      const url = `/api/ghl/users`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getAllGHLUsers:", error);
      throw error;
    }
  }

  async getAllGHLCalendars() {
    try {
      const url = `/api/ghl/calendars`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getAllGHLCalendars:", error);
      throw error;
    }
  }

  async insertOrupdateGhlContact(intent, formData, contact_id) {
    try {
      const { firstname, lastname, dob, phone_no, email, from, tags } = formData;
      const url = `/api/ghl/createOrUpdateContact`;
      const data = {
        intent: intent,
        contact_id: contact_id,
        payload: {
          firstName: firstname,
          lastName: lastname,
          dateOfBirth: dob,
          phone: phone_no,
          email,
          from,
          tags
        }
      };
      
      return await this.post(url, data);
    } catch (error) {
      console.error("Error in insertOrupdateGhlContact:", error);
      throw error;
    }
  }

  async findGhlContact(formData) {
    try {
      const { firstName, lastName, dob, phone, email } = formData;
      const url = `/api/ghl/findContact`;
      const data = {
        payload: {
          firstName,
          lastName,
          dateOfBirth: dob,
          phone,
          email
        }
      };
      
      return await this.post(url, data);
    } catch (error) {
      console.error("Error in findGhlContact:", error);
      throw error;
    }
  }
}

// Create a singleton instance to export
const ghlService = new GHLService();

// Export the methods from the instance
export const getAllGHLUsers = ghlService.getAllGHLUsers.bind(ghlService);
export const getAllGHLCalendars = ghlService.getAllGHLCalendars.bind(ghlService);
export const insertOrupdateGhlContact = ghlService.insertOrupdateGhlContact.bind(ghlService);
export const findGhlContact = ghlService.findGhlContact.bind(ghlService);
