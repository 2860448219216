import BaseService from './BaseService'
import { base64toBlob } from '../utils/base64ToBlob'

export default class IntakeService extends BaseService {
  async getAllGHLUsers(
    searchField,
    pageNo,
    pageSize,
    startDate,
    endDate,
    filterClinic,
    filterChannel,
    hasBooking,
    hasConsent,
    hasCreditCard,
    hasInsurance,
    hasGeneralInfo
  ) {
    try {
      const url = `/api/intake/getGhlContacts`;
      const config = {
        params: {
          search: searchField || null,
          limit: pageSize || null,
          pageNo: pageNo || null,
          pageSize: pageSize || null,
          startDate: startDate || null,
          endDate: endDate || null,
          filterClinic: filterClinic || null,
          channel: filterChannel || null,
          hasBooking: hasBooking || null,
          hasConsent: hasConsent || null,
          hasCreditCard: hasCreditCard || null,
          hasInsurance: hasInsurance || null,
          hasGeneralInfo: hasGeneralInfo || null
        }
      };
      
      return await this.get(url, config);
    } catch (error) {
      console.error("Error in getAllGHLUsers:", error);
      throw error;
    }
  }

  async getGHLUserById(id) {
    try {
      const url = `/api/intake/getGhlContactById`;
      const config = {
        params: { id }
      };
      
      return await this.get(url, config);
    } catch (error) {
      console.error("Error in getGHLUserById:", error);
      throw error;
    }
  }

  async getDocument(record, pdfType) {
    try {
      const url = `/api/intake/getAMDPdf`;
      const config = {
        params: {
          id: record.amdPatientID,
          pdfType: pdfType,
          universeId: record.id
        }
      };
      
      const base64Data = await this.get(url, config);
      
      if (base64Data.error) {
        return base64Data?.error;
      }
      
      const blobData = base64toBlob(base64Data, 'application/pdf');
      return blobData;
    } catch (error) {
      console.error("Error in getDocument:", error);
      throw error;
    }
  }

  async getAllAppointment(record) {
    try {
      const url = `/api/intake/getApptHistory`;
      const config = {
        params: {
          amdPatientID: record.amdPatientID,
          firstname: record.firstname,
          lastname: record.lastname,
          dob: record.dob,
          email: record.email,
          phone_no: record.phone_no
        }
      };
      
      return await this.get(url, config);
    } catch (error) {
      console.error("Error in getAllAppointment:", error);
      throw error;
    }
  }

  async getAllClinics() {
    try {
      const url = `/api/intake/getAllClinics`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getAllClinics:", error);
      throw error;
    }
  }

  async getAllChannels() {
    try {
      const url = `/api/intake/getAllChannels`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getAllChannels:", error);
      throw error;
    }
  }

  async sendIntakeEmailAndSMS(contactId, link) {
    try {
      const url = `/api/intake/sendIntakeEmailandSMS`;
      const data = {
        contactId,
        link
      };
      
      const response = await this.post(url, data);
      return response?.status === 200;
    } catch (error) {
      console.error("Error in sendIntakeEmailAndSMS:", error);
      throw error;
    }
  }

  async sendConsentCCIntakeEmailAndSMS(contactId, link) {
    try {
      const url = `/api/intake/sendConsentCCIntakeEmailandSMS`;
      const data = {
        contactId,
        link
      };
      
      const response = await this.post(url, data);
      return response?.status === 200;
    } catch (error) {
      console.error("Error in sendConsentCCIntakeEmailAndSMS:", error);
      throw error;
    }
  }

  async getProviders() {
    try {
      const url = `/api/providers`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getProviders:", error);
      throw error;
    }
  }

  async getAllAppointmentTypes() {
    try {
      const url = `/api/appointmentTypes`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getAllAppointmentTypes:", error);
      throw error;
    }
  }

  async getStates() {
    try {
      const url = `/api/states`;
      return await this.get(url);
    } catch (error) {
      console.error("Error in getStates:", error);
      throw error;
    }
  }
}

// Create a singleton instance to export
const intakeService = new IntakeService();

// Export the methods from the instance
export const getAllGHLUsers = intakeService.getAllGHLUsers.bind(intakeService);
export const getGHLUserById = intakeService.getGHLUserById.bind(intakeService);
export const getDocument = intakeService.getDocument.bind(intakeService);
export const getAllAppointment = intakeService.getAllAppointment.bind(intakeService);
export const getAllClinics = intakeService.getAllClinics.bind(intakeService);
export const getAllChannels = intakeService.getAllChannels.bind(intakeService);
export const sendIntakeEmailAndSMS = intakeService.sendIntakeEmailAndSMS.bind(intakeService);
export const sendConsentCCIntakeEmailAndSMS = intakeService.sendConsentCCIntakeEmailAndSMS.bind(intakeService);
export const getProviders = intakeService.getProviders.bind(intakeService);
export const getAllAppointmentTypes = intakeService.getAllAppointmentTypes.bind(intakeService);
export const getStates = intakeService.getStates.bind(intakeService);
