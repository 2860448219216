import React, { useState, useEffect, useRef } from "react";
import "../styles/Details.css";
import { DateRangePicker } from 'react-date-range';
import CalendarGrey from '../assets/Calendar Dark.svg'
import CalendarBlue from '../assets/Calendar Blue.svg'
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip, Spin, Tag, Modal, Card } from "antd";
import moment from "moment";
import FilterComponent from "../components/FilterComponent";
import MonthlyFaxServices from "../services/MonthlyFaxService";
import { getInsuranceVerifications } from "../services/InsuranceService";
const { Column } = Table;

function InsuranceVerifications() {
    document.title = "LunaJoy Dashboard | Insurance Verifications";
    const [data, setData] = useState([]);
    const [clinicNames, setClinicNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState([])
    const [filters, setFilters] = useState({});
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [dateRangeFilter, setDateRangeFilter] = useState([]);
    const datePickerRef = useRef(null);
    const buttonRef = useRef(null);
    const [filterClinic, setFilterClinic] = useState(null);
    const [faxType, setFaxType] = useState(null);
    const [faxStatus, setFaxStatus] = useState(null);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    },
    );

    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        pageSize: 20,
    });

    const filterCardRef = useRef(null);
    const filterButtonRef = useRef(null);

    const handleTableChange = (pagination, filters) => {
        setPagination(pagination, loadData({ ...pagination, filters: { ...dateRange, ...filters } }));
    };

    const handleClickOutside = (event) => {
        if (
            datePickerRef.current &&
            !datePickerRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)
        ) {
            setShowDatePicker(false);

        }

        if (
            filterButtonRef.current &&
            !filterButtonRef.current.contains(event.target) &&
            filterCardRef.current &&
            !filterCardRef.current.contains(event.target)
        ) {
            setIsFilterVisible(false);

        }
    };

    const handleDateChange = (ranges) => {
        console.log(ranges.selection);
        setDateRange(ranges.selection);
        loadData({ ...pagination, filters: { ...ranges.selection, ...filters } })
    };

    const handleToggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleClose = () => {
        setIsFilterVisible(false);
    };

    // dateFormatGeneric
    const momentFormatStartDate = moment().format("D/M/YYYY");
    const momentFormatEndDate = moment().format("D/M/YYYY");

    useEffect(() => {
        loadData(pagination);
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    async function loadData(pagination) {
        try {
            setLoading(true);

            const response = await getInsuranceVerifications({ ...pagination, ...dateRange, ...pagination.filters });

            setData(response?.data);
            setPagination({ ...pagination, total: response?.totalRecords });
        } catch (e) {
            console.log(e)
            Modal.confirm({
                title: 'Error Message',
                content: 'Oops! Error in Fetching data',
                okText: 'Ok',
                cancelButtonProps: { style: { display: 'none' } },
                onOk: async () => {
                    console.log("Error in Fetching data");
                },
            });
        } finally {
            setLoading(false); // Reset loading once data fetching is complete
        }
    }

    const handleFilter = () => {
        setIsFilterVisible(true);
    };

    const setSearchField = (value) => {
        setFilters({ ...filters, [value.target.name]: value.target.value || null }, loadData({ ...pagination, filters: { ...dateRange, ...filters } }));
    }

    const handleVerificationStatus = (status = '') => {
        switch(status) {
            case 'complete.eligible':
                return <Tooltip title="The verification was successfully completed. Patient is confirmed active coverage and covered for the provided taxonomy code">
                        Eligible
                    </Tooltip>
            case 'complete.ineligible':
                return <Tooltip title="The verification was successfully completed; Patient is either inactive and/or not covered for the provided taxonomy code.">
                    In-Eligible
                </Tooltip>
            case 'error.member.id':
                return <Tooltip title="The member ID was not recognized by the payer, or a different payer ID should be used.">
                    Invalid Member ID
                </Tooltip>
            case 'error.member.dob':
                return <Tooltip title="The patient’s date of birth was not recognized by the payer, or a different payer ID should be used.">
                    Invalid Patient DOB
                </Tooltip>
            case 'error.member.name':
                return <Tooltip title="The patient’s name was not recognized by the payer, or a different payer ID should be used. This is often due to incorrect spelling or nicknames.">
                    Invalid Patient Name
                </Tooltip>
            case 'error.payer':
                return <Tooltip title="The payer has been unavailable for over 24 hours and the Sohar Health Operations team have been unable to resolve the verification via payer portal or phone call.">
                    Invalid Payer
                </Tooltip>
            case 'error.payer.npi':
                return <Tooltip title="The provided NPI is not recognized by the payer. Some payers require an NPI on file in order to provide benefits. Some payers require a rendering NPI in order to provide benefits, for example Blue Cross Blue Shield of Texas.">
                    Invalid NPI
                </Tooltip>
            case 'pending':
                return <Tooltip title="The verification was resolved immediately, typically because the payer API was unavailable.">
                    Pending
                </Tooltip>
            default:
                return 'Unknown'
        }
    }

    return (
        <div id="dashboard-container">
            <div className='row styleFilterRow'>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ position: 'relative' }}>
                        <span style={{ marginLeft: "18px", fontWeight: 500, marginRight: 10 }}>Total Verifications</span>
                        <Tag>
                            <strong style={{ fontSize: 16 }}>{pagination.total}</strong>
                        </Tag>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="dateStyling" style={{ marginRight: "10px" }}>
                            Filter
                        </span>
                        <div style={{ position: "relative" }}>
                            <Tooltip title="Filter" >
                                <FilterOutlined
                                    style={{ marginRight: "10px", fontSize: "18px", marginTop: "5px", cursor: "pointer" }}
                                    ref={filterButtonRef}
                                    onClick={handleFilter}
                                />
                            </Tooltip>

                            {isFilterVisible && (
                                <div
                                    style={{ position: "absolute", top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}
                                >
                                    <Card
                                        title="Filter Options"
                                        bordered={true}
                                        extra={
                                            <Button type="Close" onClick={handleClose}>
                                                <CloseCircleOutlined />
                                            </Button>
                                        }
                                    >
                                        <FilterComponent
                                            from={"insuranceVerifications"}
                                            setSearchField={setSearchField}
                                            filters={filters}
                                        />
                                    </Card>
                                </div>
                            )}
                        </div>


                        <span className='dateStyling'>{momentFormatStartDate + ' to ' + momentFormatEndDate}</span>
                        <span ref={buttonRef} onClick={handleToggleDatePicker} className='marginAdjustCalendarToggleButton'>{showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}</span>
                        {showDatePicker && (
                            <div className="date-picker-container" ref={datePickerRef} style={{ position: 'absolute', zIndex: '9999', top: '10.5rem', display: 'flex', justifyContent: 'end', right: '30px' }}>
                                <DateRangePicker
                                    ranges={[dateRange]}
                                    onChange={handleDateChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Spin spinning={loading}>
                <Table
                    dataSource={data}
                    pagination={{
                        current: pagination.current,
                        total: pagination.total,
                        pageSize: pagination.pageSize,
                    }}
                    onChange={(...e) => {
                        if (e[3].action == 'paginate') handleTableChange(e[0], {});
                    }}
                    bordered={true}
                >
                    <Column
                        title="Member ID"
                        render={(row) => row?.response?.memberId}
                        key="verificationId"
                    />
                    <Column
                        title="Patient Name"
                        render={(row) => `${row?.patient?.firstName} ${row?.patient?.lastName}`}
                        key="bookingPatientName" />
                    <Column
                        title="Patient ID"
                        dataIndex="patientId"
                        key="patientId"
                    />
                    <Column
                        title="Payer Name"
                        render={(row) => row?.response?.payerName}
                        key="patientId"
                    />
                    <Column
                        title="Status"
                        key="response"
                        render={(row) => handleVerificationStatus(row?.response?.status)} />
                    <Column
                        title="Created At"
                        dataIndex="createdAt"
                        key="createdAt" />
                </Table>
            </Spin>

        </div >
    );
}


export default InsuranceVerifications;
