 const IsIslandBrowser = () => {
    // Island might not identify itself in the user agent string
    // Try to detect Island-specific features instead
    
    // Method 1: Check if Island-specific global variable exists
    if (window.isIslandBrowser || window.__ISLAND_BROWSER__) {
      return true;
    }
    
    // Method 2: Check for Island-specific navigator properties
    // Replace these with actual Island-specific properties if known
    if (navigator.userAgentData?.brands?.some(brand => 
      brand.brand.includes('Island'))) {
      return true;
    }
    
    // Method 3: If on a controlled domain, assume it's Island
    // If your app will only be deployed to Island-controlled domains
    if (window.location.hostname.includes('island') || 
        window.location.hostname.endsWith('.island.com')) {
      return true;
    }
    
    // Fallback: Ask the user or enable a debug flag
    // For testing, you could use a URL parameter
    if (new URLSearchParams(window.location.search).has('island-mode')) {
      return true;
    }
    
    // Default fallback - not detected as Island
    return false;
  };

  export default IsIslandBrowser;