/**
 * Auth utility functions for JWT token validation
 */

/**
 * Check if the JWT token is expired
 * @param {string} token - JWT token to validate
 * @returns {boolean} - True if token is expired or invalid, false otherwise
 */
export const isTokenExpired = (token) => {
    if (!token) return true;
    
    try {
      // Get payload part of the JWT
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));
      
      // Check if token has expired
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();
      
      return currentTime > expirationTime;
    } catch (error) {
      console.error('Error validating token:', error);
      return true; // Treat parsing errors as expired tokens
    }
  };
  
  /**
   * Get user data from localStorage
   * @returns {Object|null} - User data or null if not found
   */
  export const getUserData = () => {
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) return null;
      
      return JSON.parse(userJson);
    } catch (error) {
      console.error('Error retrieving user data:', error);
      return null;
    }
  };
  
  /**
   * Remove authentication data and return to login
   */
  export const clearAuthAndRedirectToLogin = () => {
    localStorage.removeItem('user');
    // Also remove any cookies used for authentication
    document.cookie.split(";").forEach(cookie => {
      const [name] = cookie.trim().split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
    
    window.location.href = '/login';
  }; 