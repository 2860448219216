import React, { useState, useEffect, useRef } from 'react'
import '../styles/Details.css'
import { DeleteOutlined, EditOutlined, FilterOutlined, CloseCircleOutlined, FileExcelOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tag, Tooltip, Card, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import Referral from '../assets/Referrals_Icon.svg'
import FilterComponent from '../components/FilterComponent'
import { Link } from 'react-router-dom'
import { getClinics, deleteClinic, getClinicsComplete } from '../services/ClinicsService'
import { formatDate } from '../utils/dateFormat'
import { existingPhq9Gad7Tags, existingObgynTags } from '../assets/data/existing-tags-'
import XLSX from 'sheetjs-style'
import { saveAs } from 'file-saver'

const { Column } = Table

function ClinicsDetails() {
  document.title = 'LunaJoy Dashboard'
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalComponent, setModalComponent] = useState()
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [filters, setFilters] = useState({})
  const [filterClinic, setFilterClinic] = useState(null)
  const [typeOfScreening, setTypeOfScreening] = useState([])
  const [typeOfEhr, setTypeOfEhr] = useState(null)
  const [loading, setLoading] = useState(false)
  const [clinicNames, setClinicNames] = useState([])
  const [otherClinicNames, setOtherClinicNames] = useState([])
  const [filterByOtherNameOfClinic, setFilterByOtherNameOfClinic] = useState(null)
  const [clinicDetailsModalOpen, setClinicDetailsModalOpen] = useState(false)
  const [selectedClinicDetails, setSelectedClinicDetails] = useState(null)
  const [loadingClinicDetails, setLoadingClinicDetails] = useState(false)
  
  const clinicSet = new Set()
  const otherClinicSet = new Set()

  const filterCardRef = useRef(null)
  const filterButtonRef = useRef(null)

  const bookingUrl =
    window.location.hostname === 'portal.hellolunajoy.com' ? `https://booking.hellolunajoy.com` : `https://booking-dev.hellolunajoy.com`

  const handleTableChange = (pagination, filters) => {
    setFilters(filters)
  }

  const handleClickOutside = (event) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterCardRef.current &&
      !filterCardRef.current.contains(event.target)
    ) {
      setIsFilterVisible(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [filterClinic, filterByOtherNameOfClinic, typeOfScreening, typeOfEhr])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleFilter = () => {
    setIsFilterVisible(true)
  }

  const handleClose = () => {
    setIsFilterVisible(false)
  }

  async function loadData(filter) {
    try {
      setLoading(true)
      await loadClinicData()
      const typesOfScreeningString = typeOfScreening.length !== 0 ? typeOfScreening.toString() : null
      const details = await getClinics(filterClinic, filterByOtherNameOfClinic, typesOfScreeningString, typeOfEhr)

      setData(
        details.map((data) => {
          let oblink = null

          return {
            key: data.id,
            id: data.id,
            clinicCode: data.clinicCode,
            date: data.createdAt,
            name: data.name,
            address: data.address,
            faxNumber: data.faxNumber,
            faxSequence: data.faxSequence ? data.faxSequence.toString() : data.faxSequence,
            isFaxScheduled: data.isFaxScheduled,
            ehr: data.ehr,
            ehrId: data.ehrId,
            typesOfScreen: data.typesOfScreen ? data.typesOfScreen.toString() : data.typesOfScreen,
            typesOfIntegration: data.typesOfIntegration,
            landingPageUrl: data.landingPageUrl,
            clinicians: data.clinicians ? data.clinicans.toString() : data.clinicans,
            screeningSlugs: data.screeningSlugs,
            obLink: oblink,
            alias: data.alias,
            tags: data.tags,
            referralLink: data?.referralLink ? data.referralLink : null,
            clinicGHLTags: data?.clinicGHLTags ? data.clinicGHLTags : null,
            streetName: data?.streetName ? data.streetName : null,
            specialty: data?.clinicGHLTags?.find(tag => tag.type === 'Specialty')?.value || ''
          }
        })
      )
    } catch (e) {
      console.log(e)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        onOk: async () => {
          console.log('Error in Fetching data')
        },
        cancelButtonProps: { style: { display: 'none' } }
      })
    } finally {
      setLoading(false)
    }
  }

  async function loadClinicData() {
    try {
      const details = await getClinics()
      details.map((data) => {
        clinicSet.add(data.name)
        if (data.alias?.length) {
          otherClinicSet.add(data.alias)
        }
      })
      setClinicNames([...clinicSet].sort())
      setOtherClinicNames([...otherClinicSet].sort())
    } catch (e) {
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching data',
        okText: 'Ok',
        onOk: async () => {
          console.log('Error in Fetching data')
        },
        cancelButtonProps: { style: { display: 'none' } }
      })
    }
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleCloseClinicDetailsModal = () => {
    setClinicDetailsModalOpen(false)
    setSelectedClinicDetails(null)
  }

  const handleViewClinicDetails = async (clinicId, clinicName) => {
    try {
      setLoadingClinicDetails(true)
      setClinicDetailsModalOpen(true)
      
      const allClinicsData = await getClinicsComplete()
      
      const clinicDetails = allClinicsData.find(clinic => clinic.id === clinicId)
      
      if (clinicDetails) {
        setSelectedClinicDetails(clinicDetails)
      } else {
        Modal.error({
          title: 'Error',
          content: `Could not find details for ${clinicName}`
        })
        setClinicDetailsModalOpen(false)
      }
    } catch (error) {
      console.error('Error fetching clinic details:', error)
      Modal.error({
        title: 'Error',
        content: 'Failed to load clinic details. Please try again.'
      })
      setClinicDetailsModalOpen(false)
    } finally {
      setLoadingClinicDetails(false)
    }
  }

  const handleDeleteClinic = (id, name) => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete "${name}" Clinic?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await deleteClinic(id)
          loadData()
        } catch (error) {
          console.error('Error deleting clinic:', error.message)
        }
        console.log('Deleting clinic with id:', id)
      }
    })
  }

  const columnsMapping = {
    'id': 'ID',
    'name': 'Clinic/Referring Party',
    'othername': 'Other Name',
    'code': 'Clinic Code',
    'streetname': 'Street Name',
    'address': 'State',
    'isfaxscheduled': 'Is Fax Scheduled',
    'faxnumber': 'Fax Number',
    'landingpage': 'Landing Page URL',
    'bookingUrl': 'Booking URL',
    'clinicians': 'Clinicians',
    'partnertype': 'Partner Type',
    'ehr': 'EHR',
    'ehrid': 'EHR ID',
    'typesofscreen': 'Types of Screening',
    'typesofintegration': 'Types of Integration',
    'faxsequence': 'Fax Sequence',
    'alowreferral': 'Allows Referrals',
    'tags': 'Tags',
    'referrallink': 'Referral Link',
    'faxfrequency': 'Fax Frequency',
    'reviewreportsequence': 'Review Report Sequence',
    'reviewreportfrequency': 'Review Report Frequency',
    'referralreceipientreportfrequency': 'Referral Recipient Report Frequency',
    'assessmentscreeningsequence': 'Assessment Screening Sequence',
    'assessmentscreeningfrequency': 'Assessment Screening Frequency',
    'referralstatussequence': 'Referral Status Sequence',
    'referralstatusfrequency': 'Referral Status Frequency',
    'sourceorganization': 'Source Organization',
    'specialty': 'Specialty',
    'sourceorganizationtype': 'Source Organization Type',
    'sourcetechnology': 'Source Technology',
    'sourcetechnologytype': 'Source Technology Type'
  };
  const generateExcel = (data) => {
    const transformedData = data.map((item) => {
      const excelRow = {}
      
      Object.keys(columnsMapping).forEach(apiKey => {
        const displayName = columnsMapping[apiKey]
        
        if (apiKey === 'typesofscreen' && item[apiKey]) {
          excelRow[displayName] = Array.isArray(item[apiKey]) 
            ? item[apiKey].join(', ') 
            : item[apiKey]
        }
        else if (apiKey === 'isfaxscheduled') {
          excelRow[displayName] = item[apiKey] ? 'Yes' : 'No'
        }
        else if (apiKey === 'alowreferral') {
          excelRow[displayName] = item[apiKey] ? 'Yes' : 'No'
        }
        else {
          excelRow[displayName] = item[apiKey] !== undefined ? item[apiKey] : ''
        }
      })
      
      return excelRow
    })

    const filetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
    const ws = XLSX.utils.json_to_sheet(transformedData)
    
    const columnWidths = []
    Object.values(columnsMapping).forEach(header => {
      columnWidths.push({ wch: Math.max(header.length * 1.5, 15) })
    })
    ws['!cols'] = columnWidths
    
    const headerStyle = {
      fill: {
        fgColor: { rgb: "4472C4" },
        patternType: "solid"
      },
      font: {
        color: { rgb: "FFFFFF" },
        bold: true,
        sz: 12
      },
      alignment: {
        horizontal: "center",
        vertical: "center"
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } }
      }
    }
    
    const headerRange = XLSX.utils.decode_range(ws['!ref'])
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: col })
      if (!ws[cellRef]) continue
      
      ws[cellRef].s = headerStyle
    }
    
    const wb = { Sheets: { 'Referring Parties': ws }, SheetNames: ['Referring Parties'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    return new Blob([excelBuffer], { type: filetype })
  }

  const handleExportToExcel = () => {
    try {
      setLoading(true)
      getClinicsComplete()
        .then((allClinicsData) => {
          const blobData = generateExcel(allClinicsData)
          saveAs(blobData, `Referring_Parties_${new Date().toISOString().split('T')[0]}.xlsx`)
        })
        .catch((error) => {
          console.error('Error fetching Referring_Parties data for export:', error)
          Modal.error({
            title: 'Export Error',
            content: 'Failed to fetch complete Referring_Parties data for export. Please try again.'
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (error) {
      console.error('Error in export process:', error)
      Modal.error({
        title: 'Export Error',
        content: 'Failed to export data to Excel. Please try again.'
      })
      setLoading(false)
    }
  }

  const renderClinicDetails = () => {
    if (!selectedClinicDetails) return null

    // Get mapping of API keys to display names from the existing columnsMapping
    const getDisplayName = (key) => {
      // Convert camelCase keys to lowercase to match columnsMapping format
      const normalizedKey = key.replace(/([A-Z])/g, '').toLowerCase();
      
      // Try to find a match in columnsMapping
      for (const [mapKey, displayName] of Object.entries(columnsMapping)) {
        if (key === mapKey || normalizedKey === mapKey || key.toLowerCase() === mapKey) {
          return displayName;
        }
      }
      
      // Fallback to formatting the key if no mapping exists
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();
    };

    return (
      <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <h3>{selectedClinicDetails.name}</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {Object.entries(selectedClinicDetails).map(([key, value]) => {
              if (['key', 'id'].includes(key)) return null;
              
              let displayValue = value;
              if (typeof value === 'boolean') {
                displayValue = value ? 'Yes' : 'No';
              } else if (Array.isArray(value)) {
                displayValue = value.join(', ');
              } else if (value === null || value === undefined) {
                displayValue = 'N/A';
              }
              
              // Use the new function to get a display name
              const displayKey = getDisplayName(key);
              
              return (
                <tr key={key} style={{ borderBottom: '1px solid #f0f0f0' }}>
                  <td style={{ padding: '8px', fontWeight: 'bold', width: '40%' }}>{displayKey}</td>
                  <td style={{ padding: '8px' }}>{displayValue}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div id="dashboard-container" className="responsive-container">
      <div className="row styleFilterRow">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ marginTop: 5 }}>
            <span style={{fontWeight: 500, marginRight: 10, marginTop: 13 }}>Total Clinics</span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{data.length === 0 ? 0 : data.length}</strong>
            </Tag>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span className="dateStyling" style={{ marginRight: '10px' }}>
              Filter
            </span>
            <div style={{ position: 'relative' }}>
              <Tooltip title="Filter">
                <FilterOutlined
                  style={{
                    marginRight: '20px',
                    fontSize: '18px',
                    marginTop: '5px',
                    cursor: 'pointer'
                  }}
                  onClick={handleFilter}
                  ref={filterButtonRef}
                />
              </Tooltip>

              {isFilterVisible && (
                <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }} ref={filterCardRef}>
                  <Card
                    title="Filter Options"
                    bordered={true}
                    extra={
                      <Button type="Close" onClick={handleClose}>
                        <CloseCircleOutlined />
                      </Button>
                    }
                  >
                    <FilterComponent
                      clinicNames={clinicNames}
                      filteredClinic={filterClinic}
                      setFilteredClinic={setFilterClinic}
                      otherClinicNames={otherClinicNames}
                      filteredOtherClinic={filterByOtherNameOfClinic}
                      setFilteredOtherClinic={setFilterByOtherNameOfClinic}
                      assessmentType={typeOfScreening}
                      setAssessmentType={setTypeOfScreening}
                      ehrType={typeOfEhr}
                      setEhrType={setTypeOfEhr}
                      from={'Clinic'}
                    />
                  </Card>
                </div>
              )}
            </div>
            <Tooltip title="Export to Excel">
              <Button 
                icon={<FileExcelOutlined />} 
                onClick={handleExportToExcel}
                style={{ marginRight: '10px' }}
              >
                Export
              </Button>
            </Tooltip>
            <Button onClick={() => navigate('/add-clinic')}>Add Clinic</Button>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: 5 }}>
        <span style={{ fontWeight: 500 }}>Click on Referring Party name to view more details.</span>
      </div>

      <Spin spinning={loading}>
        <Table dataSource={data} onChange={handleTableChange} bordered={true} tableLayout='fixed'>
          <Column
            title="Clinic/Referring Party"
            dataIndex="name"
            key="name"
            width={150}
            sorter={(a, b) => a.name.localeCompare(b.name)}
            render={(name, record) => (
              <>
                <div>
                  <Tooltip title={`View more details for ${name}`}>
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        handleViewClinicDetails(record.id, name);
                      }}
                      style={{ color: '#1890ff', cursor: 'pointer' }}
                    >
                      {name ? name : ''}
                    </a>
                  </Tooltip>
                </div>
                <div><span style={{ fontWeight: 500 }}>Fax:</span> {record?.faxNumber ? record.faxNumber : 'NA'}</div>
                <div><span style={{ fontWeight: 500 }}>Other Name:</span> {record?.alias ? record.alias : 'NA'}</div>
              </>
            )}
          />
          <Column
            title="Onboarded Date"
            dataIndex="date"
            key="date"
            width={110}
            render={(date) => (
              <>
                <div>{date ? formatDate(date) : ' '}</div>
              </>
            )}
          />

          <Column title="Specialty" dataIndex="specialty" key="specialty"
            render={(specialty) => (
              <>
                <div>{specialty ? 
                  <Tag color="blue" key={specialty} style={{ margin: '2px' }}>{specialty}</Tag>
                  : <Tag color="red" style={{ margin: '2px' }}>NA</Tag>}
                </div>
              </>
            )}
          />
          <Column
            title="State"
            dataIndex="address"
            key="address"
            width={150}
            render={(address) => (
              <div style={{ wordBreak: 'normal' }}>
                {address?.split(',').map((part, index, array) => (
                  <span key={index} style={{ display: 'inline-block' }}>
                    {part.trim()}{index < array.length - 1 ? ',' : ''}
                    {index < array.length - 1 && <br />}
                  </span>
                ))}
              </div>
            )}
          />

          <Column
            title="Screenings"
            dataIndex="typesOfScreen"
            key="typesOfScreen"
            width={120}
            render={(typesOfScreen, record) => (
              <>
                {typesOfScreen?.length ? (
                  <>
                    {typesOfScreen.split(',').map((tagFor) => {
                      return (
                        <>
                          <Tag color="blue" key={tagFor} style={{ margin: '2px' }}>
                            {tagFor === 'Obgyn' ? 'EPDS' : tagFor === 'Geriatric' ? 'GDS' : tagFor}
                          </Tag>
                          <br />
                        </>
                      )
                    })}
                  </>
                ) : null}
              </>
            )}
          />

          <Column
            title="Fax Sequence"
            dataIndex="faxSequence"
            key="faxSequence"
            width={200}
            render={(faxSequence, record) => (
              <>
                {record.isFaxScheduled ? (
                  <>
                    {faxSequence?.length ? (
                      <>
                        {faxSequence.split(',').map((element) => {
                          return (
                            <>
                              <Tag color="blue" key={element} style={{ margin: '2px' }}>
                                {element}
                              </Tag>
                              <br />
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <Tag color="blue" style={{ margin: '2px' }}>
                        Not Available
                      </Tag>
                    )}
                  </>
                ) : (
                  <Tag color="blue" style={{ margin: '2px' }}>
                    Not Available
                  </Tag>
                )}
              </>
            )}
          />

          <Column
            title="Links"
            dataIndex="landingPageUrl"
            key="landingPageUrl"
            width={150}
            minWidth={150}
            render={(landingPageUrl, record) => (
              <>
                {landingPageUrl ? (
                  <>
                    {' '}
                    <a href={landingPageUrl} target="_blank" rel="noreferrer">
                      Landing Page{' '}
                    </a>{' '}
                    <br />
                  </>
                ) : null}
                <a href={`${bookingUrl}/?referal=${record.clinicCode}`} target="_blank" rel="noreferrer">
                  Booking
                </a>
                <br />

                {record?.referralLink ? (
                  <>
                    {' '}
                    <a href={record?.referralLink} target="_blank" rel="noreferrer">
                      Referral Link{' '}
                    </a>{' '}
                    <br />
                  </>
                ) : (
                  <>
                    {' '}
                    Referral Link : NA
                    <br />
                  </>
                )}
              </>
            )}
          />

          <Column
            title="Tags"
            dataIndex="typesOfScreen"
            key="tags"
            // width={300}
            render={(typesOfScreen, record) => {
              const clinicNameTag = () => {
                const clinicname = record?.name || '';
                const streetname = record?.streetName?.trim() || '';
                const state = record?.address || '';

                let specialty = '';
                if (record?.clinicGHLTags?.length) {
                  specialty = record.clinicGHLTags.find(ghlTag => ghlTag?.type === 'Specialty')?.value || '';
                }

                const input = [clinicname, specialty, streetname, state].filter(Boolean);
                return input.join('_').toLowerCase();
              }

              const tagStyle = { margin: '2px' };

              const renderScreeningTag = (tagFor) => {
                switch (tagFor) {
                  case 'Obgyn':
                    return (
                      <Tag color="blue" key={tagFor} style={{ ...tagStyle, ...{ whiteSpace: 'normal', wordBreak: 'break-word' } }}>
                        {existingObgynTags[record.name] || `obgyn: ${record.tags}`}
                      </Tag>
                    );
                  case 'PHQ9-GAD7':
                    return (
                      <Tag color="blue" key={tagFor} style={{ ...tagStyle, ...{ whiteSpace: 'normal', wordBreak: 'break-word' } }}>
                        {existingPhq9Gad7Tags[record.name] || `phq9-gad7: ${record.tags}`}
                      </Tag>
                    );
                  case 'Geriatric':
                    return (
                      <Tag color="blue" key={tagFor} style={{ ...tagStyle, ...{ whiteSpace: 'normal', wordBreak: 'break-word' } }}>
                        {`gds: ${record.tags}`}
                      </Tag>
                    );
                  default:
                    return null;
                }
              };

              return (
                <>
                  {/* Clinic Tag */}
                  <Tag color="blue" key="clinic" style={{ ...tagStyle, ...{ whiteSpace: 'normal', wordBreak: 'break-word' } }}>
                    {record.tags}
                  </Tag>

                  {record?.typesOfScreen?.split(',').map((tagFor) => (
                    <div key={tagFor}>
                      {renderScreeningTag(tagFor)}
                    </div>
                  ))}

                  {record?.clinicGHLTags?.map((ghlTag) => (
                    <Tag color="blue" key={ghlTag.id} style={{ ...tagStyle, ...{ whiteSpace: 'normal', wordBreak: 'break-word' } }}>
                      {ghlTag?.value}
                    </Tag>
                  ))}

                  {/* Clinic Name Tag */}
                  <Tag color="blue" key={record.id} style={{ ...tagStyle, ...{ whiteSpace: 'normal', wordBreak: 'break-word' } }}>
                    {clinicNameTag()}
                  </Tag>
                </>
              );
            }
            }
          />

          <Column
            title="Action"
            dataIndex="id"
            key="id"
            width={130}
            minWidth={130}
            render={(id, record) => (
              <>
                <Tooltip title={'Delete'}>
                  <DeleteOutlined style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => handleDeleteClinic(id, record.name)} />
                </Tooltip>
                <Tooltip title={'Edit'}>
                  <Link to={`/edit-clinic/${id}`} style={{ color: 'black', textDecoration: 'none' }}>
                    <EditOutlined style={{ marginRight: '10px', cursor: 'pointer' }} />
                  </Link>
                </Tooltip>
                <Tooltip title={'Referral'}>
                  <Link to={`/referrals?carePartner=${id}`} style={{ color: 'black', textDecoration: 'none' }}>
                    <img src={Referral} alt="Referral Icon" style={{ width: '20px' }} />
                  </Link>
                </Tooltip>
                {/* <Tooltip title={"Screening"}><Link to={`/forms?clinic=${record.name}`} style={{ color: "black", textDecoration: "none" }}><img src={Submission} alt="Submission Icon" style={{ width: "20px" }} /></Link></Tooltip> */}
              </>
            )}
          />
        </Table>
      </Spin>

      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
        centered={true}
        style={{ minHeight: '300px', fontSize: '1rem' }}
      >
        {modalComponent}
      </Modal>

      <Modal
        title="Clinic Details"
        open={clinicDetailsModalOpen}
        onCancel={handleCloseClinicDetailsModal}
        footer={[
          <Button key="close" onClick={handleCloseClinicDetailsModal}>
            Close
          </Button>
        ]}
        maskClosable={true}
        centered={true}
        width={700}
        style={{ top: 20 }}
      >
        <Spin spinning={loadingClinicDetails}>
          {renderClinicDetails()}
        </Spin>
      </Modal>
    </div>
  )
}

export default ClinicsDetails
