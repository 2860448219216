import React, { useEffect } from "react";
import { BACKEND_URL } from '../utils/urls';
import axios from 'axios';
import { isTokenExpired } from '../utils/auth';

export default function LoginSuccess() {
    const fetchUser = async () => {
        const response = await axios
            .get(`${BACKEND_URL}/api/auth/user`, { withCredentials: true })
            .catch((err) => {
                console.log("Not properly authenticated");
                window.close();
            });
        
        if (response && response.data) {
            const token = response.data.jwtToken;
            
            // Validate the token before storing it
            if (token && !isTokenExpired(token)) {
                const user = JSON.stringify({
                    name: response.data.name,
                    id: response.data.id,
                    email: response.data.email,
                    jwtToken: token
                });
                localStorage.setItem('user', user);
            } else {
                console.error("Received invalid or expired token from server");
            }
        }
        window.close();
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return <div>Thanks for logging in!</div>;
}
