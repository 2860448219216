/**
 * Simple cache utility for storing data with an expiration timestamp
 */

// In-memory cache storage
const cacheStore = {
  data: {},
  timestamps: {}
};

/**
 * Set data in the cache with expiration
 * 
 * @param {string} key - Cache key
 * @param {any} data - Data to cache
 * @param {number} expirationMs - Expiration time in milliseconds (default: 1 hour)
 */
export const setCache = (key, data, expirationMs = 60 * 60 * 1000) => {
  cacheStore.data[key] = data;
  cacheStore.timestamps[key] = new Date().getTime();
  
  console.log(`Cache set for key: ${key}`);
  return data;
};

/**
 * Get data from cache if it exists and is not expired
 * 
 * @param {string} key - Cache key
 * @param {number} expirationMs - Expiration time in milliseconds (default: 1 hour)
 * @returns {any|null} - Cached data or null if not found/expired
 */
export const getCache = (key, expirationMs = 60 * 60 * 1000) => {
  const data = cacheStore.data[key];
  const timestamp = cacheStore.timestamps[key];
  
  if (!data || !timestamp) {
    console.log(`Cache miss for key: ${key}`);
    return null;
  }
  
  const currentTime = new Date().getTime();
  if (currentTime - timestamp > expirationMs) {
    console.log(`Cache expired for key: ${key}`);
    // Clear expired data
    delete cacheStore.data[key];
    delete cacheStore.timestamps[key];
    return null;
  }
  
  console.log(`Cache hit for key: ${key}`);
  return data;
};

/**
 * Invalidate (remove) cached data for a specific key
 * 
 * @param {string} key - Cache key
 */
export const invalidateCache = (key) => {
  delete cacheStore.data[key];
  delete cacheStore.timestamps[key];
  console.log(`Cache invalidated for key: ${key}`);
};

/**
 * Clear all cached data
 */
export const clearCache = () => {
  Object.keys(cacheStore.data).forEach(key => {
    delete cacheStore.data[key];
    delete cacheStore.timestamps[key];
  });
  console.log(`All cache cleared`);
}; 