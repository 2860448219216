import { BACKEND_URL } from "../utils/urls";
import axios from 'axios';
import { isTokenExpired, clearAuthAndRedirectToLogin } from '../utils/auth';

// Create axios instance with default config
const apiClient = axios.create({
  baseURL: BACKEND_URL
});

// Add response interceptor to handle auth errors
apiClient.interceptors.response.use(
  response => response, // Return successful responses as-is
  error => {
    // Handle 401 (Unauthorized) or 403 (Forbidden) responses
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      console.log('Session expired or unauthorized access. Redirecting to login...');
      clearAuthAndRedirectToLogin();
    }
    return Promise.reject(error);
  }
);

class BaseService {
  /**
   * Base API URL for all requests
   * @type {string}
   */
  
  /**
   * Get the latest token from localStorage
   * @returns {string|null} - The JWT token or null if not available
   */
  getToken() {
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) return null;
      
      const user = JSON.parse(userJson);
      const token = user?.jwtToken || null;
      
      // Check if token is expired
      if (token && isTokenExpired(token)) {
        console.log('Token expired. Redirecting to login...');
        clearAuthAndRedirectToLogin();
        return null;
      }
      
      return token;
    } catch (error) {
      console.error('Error retrieving token:', error);
      return null;
    }
  }

  /**
   * Get default axios config with authorization headers
   * @param {Object} customConfig - Custom config to merge
   * @returns {Object} - Config with auth headers
   */
  getAxiosConfig(customConfig = {}) {
    const token = this.getToken();
    const config = { ...customConfig };
    
    if (!config.headers) {
      config.headers = {};
    }
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
  }

  /**
   * Performs a GET request
   * @param {string} endpoint - API endpoint
   * @param {Object} config - Optional axios config (params, headers, etc)
   * @returns {Promise<any>} - Response data
   */
  async get(endpoint, config = {}) {
    try {
      const url = `${BACKEND_URL}${endpoint}`;
      const axiosConfig = this.getAxiosConfig(config);
      
      const response = await apiClient.get(url, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('GET request failed:', error);
      throw error;
    }
  }

  /**
   * Performs a POST request
   * @param {string} endpoint - API endpoint
   * @param {Object} data - Request body data
   * @param {Object} config - Optional axios config (params, headers, etc)
   * @returns {Promise<any>} - Response data
   */
  async post(endpoint, data, config = {}) {
    try {
      const url = `${BACKEND_URL}${endpoint}`;
      const axiosConfig = this.getAxiosConfig(config);
      
      const response = await apiClient.post(url, data, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('POST request failed:', error);
      throw error;
    }
  }

  /**
   * Performs a PUT request
   * @param {string} endpoint - API endpoint
   * @param {Object} data - Request body data
   * @param {Object} config - Optional axios config (params, headers, etc)
   * @returns {Promise<any>} - Response data
   */
  async put(endpoint, data, config = {}) {
    try {
      const url = `${BACKEND_URL}${endpoint}`;
      const axiosConfig = this.getAxiosConfig(config);
      
      const response = await apiClient.put(url, data, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('PUT request failed:', error);
      throw error;
    }
  }

  /**
   * Performs a DELETE request
   * @param {string} endpoint - API endpoint
   * @param {Object} config - Optional axios config (params, headers, etc)
   * @returns {Promise<any>} - Response data
   */
  async delete(endpoint, config = {}) {
    try {
      const url = `${BACKEND_URL}${endpoint}`;
      const axiosConfig = this.getAxiosConfig(config);
      
      const response = await apiClient.delete(url, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('DELETE request failed:', error);
      throw error;
    }
  }
}

export default BaseService;
