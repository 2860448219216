import BaseService from './BaseService';

export default class InsuranceCaseManagerService extends BaseService {
    async getCaseManagerLinks() {
        try {
            return await this.get('/api/insuranceCaseManager/getCaseManagerLinks');
        } catch (error) {
            console.error("Error fetching case manager links:", error);
            throw new Error('Could not fetch case manager links.');
        }
    }
}

// Create a singleton instance
const insuranceCaseManagerService = new InsuranceCaseManagerService();

// Export the methods from the instance
export const getCaseManagerLinks = insuranceCaseManagerService.getCaseManagerLinks.bind(insuranceCaseManagerService);
